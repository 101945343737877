import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { AxiosInstance, AxiosResponse } from 'axios';
export interface LoginRepository {
    (username: string, password: string): Promise<JwtAuthToken>
}

const loginRepostory = (axios: AxiosInstance): LoginRepository => async (username: string, password: string) => {
    try {
        const response: AxiosResponse = await axios.post('/session', {
            "login": username,
            "password": password
        })

        const jwtAuthToken: JwtAuthToken = response.data
        return jwtAuthToken
    } catch (error) {
        throw error
    }
}

export default loginRepostory