import { FetchMostLikedPostsRepository } from '../../data/repository/fetch_most_liked_post_repository';
import { Post } from '@/module/post/domain/model/post';

export interface FetchMostLikedPostsUseCase {
    (): Promise<Post[]>
}

const fetchMostLikedPostsUseCase = (repository: FetchMostLikedPostsRepository): FetchMostLikedPostsUseCase => async () => {
    try {
        return await repository();
    } catch (error) {
        throw error
    }
}

export default fetchMostLikedPostsUseCase;