import { LeadPagination } from '../../domain/model/lead_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface FetchLeadRepository {
    (pagination: Pagination): Promise<LeadPagination>
}

const fetchLeadRepository = (axios: AxiosInstance): FetchLeadRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/lead', {
            params: pagination
        })

        const leadPagination: LeadPagination = response.data
        return leadPagination
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchLeadRepository