import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Post } from '../../domain/model/post';

export interface CreatePostRepository {
  (data: object): Promise<Post>
}

const createPostRepository = (axios: AxiosInstance): CreatePostRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/post`,
      data,
    )
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default createPostRepository

