import { Post } from '@/module/post/domain/model/post';
import { PostPagination } from '../../domain/model/post_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface FetchSuccessHistoryPostsRepository {
    (): Promise<Post[]>
}

const fetchSuccessHistoryPostsRepository = (axios: AxiosInstance): FetchSuccessHistoryPostsRepository => async () => {
    try {
        const response: AxiosResponse = await axios.get('/success-history/post')

        const postsPagination: Post[] = response.data
        return postsPagination
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchSuccessHistoryPostsRepository