import { CreateCategoryRepository } from '../../data/repository/create_category_repository';
import { Category } from '../model/category';

export interface CreateCategoryUseCase {
    (data: object): Promise<Category>
}

const createCategoryUseCase = (repository: CreateCategoryRepository): CreateCategoryUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createCategoryUseCase;

