import { ChangeVacancyRepository } from '../../data/repository/change_vacancy_repository';
import { Vacancy } from '../model/vacancy';

export interface ChangeVacancyUseCase {
    (id: number, data: object): Promise<Vacancy>
}

const changeVacancyUseCase = (repository: ChangeVacancyRepository): ChangeVacancyUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeVacancyUseCase;

