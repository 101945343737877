import { DeleteLeadRepository } from '../../data/repository/delete_lead_repository';
import { Lead } from '../model/lead';

export interface DeleteLeadUseCase {
    (id: number): Promise<Lead>
}

const deleteLeadUseCase = (repository: DeleteLeadRepository): DeleteLeadUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteLeadUseCase;