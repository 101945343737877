import { Vacancy } from '../../domain/model/vacancy';
import { VacancyPagination } from '../../domain/model/vacancy_pagination';
import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { Pagination } from '@/core/domain/model/pagination';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteVacancyRepository {
    (id: number): Promise<Vacancy>
}

const deleteVacancyRepository = (axios: AxiosInstance): DeleteVacancyRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/vacancy/${id}`)
        const vacancy: Vacancy = response.data
        return vacancy
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default deleteVacancyRepository