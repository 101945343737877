import { Lead } from '../../domain/model/lead';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface DeleteLeadRepository {
    (id: number): Promise<Lead>
}

const deleteLeadRepository = (axios: AxiosInstance): DeleteLeadRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/lead/${id}`)
        const lead: Lead = response.data
        return lead
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default deleteLeadRepository