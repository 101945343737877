<template>
  <div>
    <v-toolbar elevation="0">
      <v-icon @click="navigation.navigate(0)">mdi-arrow-left</v-icon>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-data-table
      class="elevation-2 ma-2"
      dense
      height="70vh"
      fixed-header
      :headers="controller.columns"
      :options.sync="controller.options"
      :server-items-length="controller.vacancyPagination.total"
      :items="controller.vacancyPagination.items"
      :loading="controller.loading"
      :footer-props="{
        'items-per-page-options': [40, 80],
      }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            color="primary"
            class="mt-0 pt-0"
            v-model="item.active"
            hide-details
            readonly
            dense
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn
            icon
            @click="controller.change(item)"
            class="px-2"
          >
            <v-icon
              small
              color="blue"
            >mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="controller.delete(item)"
            class="px-2"
          >
            <v-icon
              small
              color="red"
            >mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Vagas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            class="px-2"
            style="max-width: 250px"
            filled
            color="primary"
            hide-details
            dense
            placeholder="Pesquisar..."
            v-model="controller.search"
          ></v-text-field>
          <v-dialog
            scrollable
            persistent
            v-model="controller.dialog"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="hadPermission(4, 1)"
                color="primary"
                dark
                height="40"
                v-bind="attrs"
                v-on="on"
                @click.stop="controller.open()"
              >
                <v-icon left>mdi-plus</v-icon>
                Novo
              </v-btn>
            </template>

            <v-card>
              <div class="primary d-flex justify-center">
                <v-card-title style="color: white">
                  Manutenção vaga
                </v-card-title>
              </div>
              <v-divider></v-divider>
              <v-card-text class="py-5">
                <v-form
                  ref="crud"
                  lazy-validation
                >
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Descrição"
                    v-model="controller.vacancy.name"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-text-field
                    filled
                    dense
                    color="primary"
                    label="Link do formulário do google"
                    v-model="controller.vacancy.formLink"
                    :rules="[(v) => !!v || 'Preencha o campo']"
                  >
                  </v-text-field>
                  <v-checkbox
                    dense
                    v-model="controller.vacancy.active"
                    label="Ativa"
                  ></v-checkbox>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="px-6 py-4">
                <v-btn
                  @click="controller.close()"
                  outlined
                  color="red"
                >
                  <v-icon left>mdi-delete</v-icon>cancelar
                </v-btn>
                <v-spacer> </v-spacer>
                <v-btn
                  color="primary"
                  @click="controller.create()"
                >
                  <v-icon left>mdi-content-save</v-icon>salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { vacancyController } from "@/di/di";
export default {
  data: (context) => ({
    controller: vacancyController(context),
  }),
  watch: {
    "controller.options"() {
      this.controller.watchOptions();
    },
    "controller.search"() {
      this.controller.watchSearch();
    },
  },
};
</script>