import { ChangePostRepository } from '../../data/repository/change_post_repository';
import { Post } from '../model/post';

export interface ChangePostUseCase {
    (id: number, data: object): Promise<Post>
}

const changePostUseCase = (repository: ChangePostRepository): ChangePostUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changePostUseCase;

