import { FetchAccountPermissionsUseCase } from '@/core/domain/usecase/fetch_account_permissions_usecase'
import { getAccountGroupID, getAccountName } from '@/core/domain/model/jwtAuthToken';
import DashboardPage from '../../dashboard/view/dashboard_page.vue'
import { snackbar } from '@/core/controller/snackbar_controller';
import UserPage from '../../user/view/user_page.vue'
import PostPage from '../../post/view/post_page.vue'
import PostEditorPage from '../../post/view/post_editor_page.vue'
import CategoryPage from '../../category/view/category_page.vue'
import TagPage from '../../tag/view/tag_page.vue'
import LeadPage from '../../lead/view/lead_page.vue'
import IndicatorPage from '../../indicator/view/indicator_page.vue'
import VacancyPage from '../../vacancy/view/vacancy_page.vue'
import Vue from 'vue';

class HomeController {
    public context: any;
    public selected: any = null;
    public mini: boolean = true
    public sidebarMenu: boolean = true
    public userMenu: boolean = false
    public fetchAccountPermissionsUseCase: FetchAccountPermissionsUseCase
    public dark: boolean = false
    public userName: string = ""
    public loading: boolean = true;
    public params: any = {}

    constructor(
        context: any,
        fetchAccountPermissionsUseCase: FetchAccountPermissionsUseCase,
    ) {
        this.context = context;
        this.fetchAccountPermissionsUseCase = fetchAccountPermissionsUseCase;
    }

    async navigate(page: number) {
        this.params = null
        this.goTo(page)
    }

    async navigateWithParams(page: number, params: any) {
        this.params = params
        this.goTo(page)
    }

    private goTo(page) {
        switch (page) {
            case 0:
                this.selected = DashboardPage
                break
            case 1:
                this.selected = PostPage
                break
            case 2:
                this.selected = UserPage
                break
            case 3:
                this.selected = PostEditorPage
                break
            case 4:
                this.selected = CategoryPage
                break
            case 5:
                this.selected = TagPage
                break
            case 6:
                this.logout()
                break
            case 7:
                this.selected = LeadPage
                break
            case 8:
                this.selected = IndicatorPage
                break
            case 9:
                this.selected = VacancyPage
                break
            default:
                break
        }

        if (this.context.$vuetify.breakpoint.mobile) {
            this.sidebarMenu = false
        }
    }

    logout() {
        localStorage.removeItem('auth');
        window.router.login()
    }

    async mounted() {
        try {
            this.dark = localStorage.getItem("dark") === "true" ? true : false;

            window.navigation = this;
            Vue.prototype.navigation = this
            const accountGroupID = getAccountGroupID()

            Vue.prototype.permissions = await this.fetchAccountPermissionsUseCase(
                accountGroupID
            );

            this.userName = getAccountName()

            this.selected = DashboardPage

            setTimeout(() => {
                this.loading = false
            }, 500)
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }
}

export default HomeController