import { DeleteVacancyRepository } from '../../data/repository/delete_vacancy_repository';
import { Vacancy } from '../model/vacancy';

export interface DeleteVacancyUseCase {
    (id: number): Promise<Vacancy>
}

const deleteVacancyUseCase = (repository: DeleteVacancyRepository): DeleteVacancyUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteVacancyUseCase;