<template>
  <v-app>
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        class="pa-0"
        cols="12"
        lg="3"
      >
        <v-card
          dark
          max-height="800"
          style="overflow-y: auto"
          class="pa-8"
          color="#14171E"
        >
          <v-card-title class="mb-10">
            <v-spacer></v-spacer>
            <v-img
              class="mx-5"
              max-width="200"
              contain
              src="/greennext.png"
            ></v-img>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>

            <v-form
              ref="form"
              v-if="!controller.newAccount && !controller.newCompany"
            >
              <div class="white--text">Usuário ou E-mail</div>
              <v-text-field
                :rules="[(v) => !!v || 'Preencha o campo']"
                filled
                v-model="controller.username"
                placeholder="@email"
              ></v-text-field>
              <div class="white--text">Sua senha</div>
              <v-text-field
                filled
                v-model="controller.password"
                :rules="[(v) => !!v || 'Preencha o campo']"
                type="password"
                placeholder="Senha"
              ></v-text-field>
              <v-btn
                @click="controller.login()"
                class="mt-5"
                color="primary"
                block
                height="50"
                :loading="controller.loading"
              >Entrar</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { loginController } from "@/di/di";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  data: (context) => ({
    controller: loginController(context),
  }),
};
</script>
