import { Post } from './../domain/model/post';
import { snackbar } from "@/core/controller/snackbar_controller";
import { FetchPostsUseCase } from "../domain/usecase/fetch_post_usecase";
import { PostPagination } from "../domain/model/post_pagination";
import { defaultModelPagination, defaultPagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { DeletePostUseCase } from "../domain/usecase/delete_post_usecase";
import { FetchPublicatedPostsUseCase } from '../domain/usecase/fetch_publicated_post_usecase';
import { FetchDraftPostsUseCase } from '../domain/usecase/fetch_draft_post_usecase';
import { FetchHighlightedPostsUseCase } from '../domain/usecase/fetch_highlighted_post_usecase';

class PostController {
    public postPagination: PostPagination = defaultModelPagination()
    public postOptions: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["publicationDate"], sortDesc: [true] })
    public search: string = ""
    public optionFilter = [
        {
            id: 0,
            name: "Todos",
        },
        {
            id: 1,
            name: "Publicados",
        },
        {
            id: 2,
            name: "Em destaque",
        },
        {
            id: 3,
            name: "Rascunhos",
        }
    ]
    public filter: number = 0

    constructor(
        private context: any,
        private fetchPostsUseCase: FetchPostsUseCase,
        private deletePostUseCase: DeletePostUseCase,
        private fetchPublicatedPostUseCase: FetchPublicatedPostsUseCase,
        private fetchDraftPostUseCase: FetchDraftPostsUseCase,
        private fetchHighlightedPostUseCase: FetchHighlightedPostsUseCase,
    ) { }

    async mounted() {
        try {
            this.postPagination = await this.fetchPostsUseCase(this.postOptions, "")
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    async deletePost(id: number) {
        try {
            const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o post?", color: "red", timeout: 5000 })

            if (confirm) {
                await this.deletePostUseCase(id)
                this.mounted()
            }
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    async editPost(post: Post) {
        try {
            const confirm = await snackbar.confirm({ message: "Deseja realmente alterar o post?", color: "blue", timeout: 5000 })

            if (confirm) {
                window.navigation.navigateWithParams(3, post)
            }
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    async watchSearch() {
        this.find()
    }

    async watchFilter() {
        this.find()
    }

    private async find() {
        switch (this.filter) {
            case 0:
                this.postPagination = await this.fetchPostsUseCase(this.postOptions, this.search)
                break
            case 1:
                this.postPagination = await this.fetchPublicatedPostUseCase(this.postOptions, this.search)
                break;
            case 2:
                this.postPagination = await this.fetchHighlightedPostUseCase(this.postOptions, this.search)
                break
            case 3:
                this.postPagination = await this.fetchDraftPostUseCase(this.postOptions, this.search)
                break

        }
    }
}

export default PostController