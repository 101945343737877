<template>
  <div>
    <v-row class="pa-2 ma-2">
      <v-col lg="4" v-if="hadPermission(1, 4)">
        <v-card @click="navigation.navigate(1)">
          <v-card-text>
            Publicações
          </v-card-text>
        </v-card>
      </v-col>
       <v-col lg="4" v-if="hadPermission(5, 4)">
        <v-card @click="navigation.navigate(2)">
          <v-card-text>
            Usuários
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" v-if="hadPermission(3, 4)">
        <v-card @click="navigation.navigate(4)">
          <v-card-text>
            Categorias
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" v-if="hadPermission(4, 4)">
        <v-card @click="navigation.navigate(5)">
          <v-card-text>
            Tags
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" v-if="hadPermission(2, 4)">
        <v-card @click="navigation.navigate(7)">
          <v-card-text>
            Leads
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4">
        <v-card @click="navigation.navigate(8)">
          <v-card-text>
            Indicadores
          </v-card-text>
        </v-card>
      </v-col>
       <v-col lg="4">
        <v-card @click="navigation.navigate(9)">
          <v-card-text>
            Vagas
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4">
        <v-card @click="navigation.navigate(6)">
          <v-card-text>
            Sair
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { dashboardController } from "@/di/di";
import Lottie from "../../../core/component/lottie.vue";

export default {
  components: {
    Lottie,
  },
  data: (context) => ({
    controller: dashboardController(context),
  }),
};
</script>
