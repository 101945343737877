<template>
  <div class="mx-5">
    <v-row justify="center">
      <v-col>
        <v-toolbar elevation="0">
          <v-icon @click="navigation.navigate(0)">mdi-arrow-left</v-icon>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>

    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="4"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            Top 3 posts mais acessados
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div
              id="top3_chart"
              style="height: 50vh; width: 100%"
            ></div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            Top 3 posts mais curtidos
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div
              id="top3_chart_liked"
              style="height: 50vh; width: 100%"
            ></div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            Top 3 posts mais compartilhados
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div
              id="top3_chart_shared"
              style="height: 50vh; width: 100%"
            ></div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            Relação entre compartilhamentos, likes e views
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div
              class="d-flex justify-center align-center"
              style="height: 50vh; width: 100%"
            >Em andamento</div>
          </v-card-text>
        </v-card>
      </v-col> -->

      <v-col
        cols="12"
        lg="6"
        v-if="hadPermission(2, 4)"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            Últimos leads cadastrados
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(item, index) in controller.lastLeads"
                :key="index"
              >
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-podium-gold
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-html="item.description"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            10 melhores posts
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(item, index) in controller.top10PostsLiked"
                :key="index"
              >
                <v-list-item-icon>
                  <v-icon color="primary">
                    mdi-podium-gold
                  </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <div class="d-flex">
                    <div class="px-2">
                      <v-icon color="red">mdi-heart</v-icon> {{ item.status.likes }}
                    </div>
                    <div class="px-2">
                      <v-icon
                        color="blue"
                        left
                      >mdi-eye</v-icon> {{ item.status.views }}
                    </div>
                    <div class="px-2">
                      <v-icon color="orange">mdi-share</v-icon> {{ item.status.shares }}
                    </div>
                  </div>
                </v-list-item-action>
              </v-list-item>

            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { indicatorController } from "@/di/di";
export default {
  data: (context) => ({
    controller: indicatorController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
};
</script>
