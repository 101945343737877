var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-icon',{on:{"click":function($event){return _vm.navigation.navigate(0)}}},[_vm._v("mdi-arrow-left")]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-2 ma-2",attrs:{"dense":"","height":"70vh","fixed-header":"","headers":_vm.controller.columns,"options":_vm.controller.options,"server-items-length":_vm.controller.categoryPagination.total,"items":_vm.controller.categoryPagination.items,"loading":_vm.controller.loading,"footer-props":{
      'items-per-page-options': [40, 80],
    }},on:{"update:options":function($event){return _vm.$set(_vm.controller, "options", $event)}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"color":"primary","hide-details":"","readonly":"","dense":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-btn',{staticClass:"px-2",attrs:{"icon":""},on:{"click":function($event){return _vm.controller.change(item)}}},[_c('v-icon',{attrs:{"small":"","color":"blue"}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Categorias")]),_c('v-spacer'),_c('v-text-field',{staticClass:"px-2",staticStyle:{"max-width":"250px"},attrs:{"filled":"","color":"primary","hide-details":"","dense":"","placeholder":"Pesquisar..."},model:{value:(_vm.controller.search),callback:function ($$v) {_vm.$set(_vm.controller, "search", $$v)},expression:"controller.search"}}),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.hadPermission(3, 1))?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","height":"40"},on:{"click":function($event){$event.stopPropagation();return _vm.controller.open()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Novo ")],1):_vm._e()]}}]),model:{value:(_vm.controller.dialog),callback:function ($$v) {_vm.$set(_vm.controller, "dialog", $$v)},expression:"controller.dialog"}},[_c('v-card',[_c('div',{staticClass:"primary d-flex justify-center"},[_c('v-card-title',{staticStyle:{"color":"white"}},[_vm._v(" Manutenção usuário ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-5"},[_c('v-form',{ref:"crud",attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"filled":"","dense":"","color":"primary","label":"Nome","rules":[function (v) { return !!v || 'Preencha o campo'; }]},model:{value:(_vm.controller.category.name),callback:function ($$v) {_vm.$set(_vm.controller.category, "name", $$v)},expression:"controller.category.name"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"px-6 py-4"},[_c('v-btn',{attrs:{"outlined":"","color":"red"},on:{"click":function($event){return _vm.controller.close()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("cancelar ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.controller.create()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("salvar ")],1)],1)],1)],1)],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }