import { VacancyPagination } from '../../domain/model/vacancy_pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Pagination } from '@/core/domain/model/pagination';

export interface FetchVacancyRepository {
    (pagination: Pagination): Promise<VacancyPagination>
}

const fetchVacancyRepository = (axios: AxiosInstance): FetchVacancyRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/vacancy', {
            params: pagination
        })

        const vacancyPagination: VacancyPagination = response.data
        return vacancyPagination
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchVacancyRepository