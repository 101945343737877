import { PostPagination } from '../../domain/model/post_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface FetchPublicatedPostsRepository {
    (pagination: Pagination): Promise<PostPagination>
}

const fetchPublicatedPostsRepository = (axios: AxiosInstance): FetchPublicatedPostsRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/publicated/post', {
            params: pagination
        })

        const postsPagination: PostPagination = response.data
        return postsPagination
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchPublicatedPostsRepository