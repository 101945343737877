export interface Lead {
    id: number;
    pageFrom: string;
    description: string;
    createdDate: string;
}


const en: any = {
    id: 1,
    pageFrom: 2,
    description: 3,
    createdDate: 4
};
export { en };
