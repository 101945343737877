import { Tag } from '../../domain/model/tag';
import { TagPagination } from '../../domain/model/tag_pagination';
import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { Pagination } from '@/core/domain/model/pagination';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteTagRepository {
    (id: number): Promise<Tag>
}

const deleteTagRepository = (axios: AxiosInstance): DeleteTagRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/cateogory/${id}`)
        const tag: Tag = response.data
        return tag
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default deleteTagRepository