
import DefaultController from "@/module/default/controller/default_controller";
import LoginController from '@/module/login/controller/login_controller';
import HomeController from '@/module/home/controller/home_controller';
import UserController from '@/module/user/controller/user_controller';
import { baseUrl } from '@/constants'
import axios from 'axios';
import loginRepostory from "@/core/data/repository/login_repository";
import loginUseCase from "@/core/domain/usecase/login_usecase";
import refreshRepostory from "@/core/data/repository/refresh_repository";
import refreshUseCase from "@/core/domain/usecase/refresh_usecase";

//User
import fetchUserRepository from "@/module/user/data/repository/fetch_user_repository";
import fetchUserUseCase from "@/module/user/domain/usecase/fetch_user_usecase";
import deleteUserRepository from "@/module/user/data/repository/delete_user_repository";
import deleteUserUseCase from "@/module/user/domain/usecase/delete_user_usecase";
import createUserRepository from "@/module/user/data/repository/create_user_repository";
import createUserUseCase from "@/module/user/domain/usecase/create_user_usecase";
import changeUserRepository from "@/module/user/data/repository/change_user_repository";
import changeUserUseCase from "@/module/user/domain/usecase/change_user_usecase";

//Home
import fetchAccounPermissionsRepository from "@/core/data/repository/fetch_account_permissions_repository";
import fetchAccountPermissionsUseCase from "@/core/domain/usecase/fetch_account_permissions_usecase";
import DashboardController from "@/module/dashboard/controller/dashboard_controller";
import PostEditorController from "@/module/post/controller/post_editor_controller";
import { axosInstance } from "./axios_instance";
import fetchPostsRepository from "@/module/post/data/repository/fetch_post_repository";
import fetchPostsUseCase from "@/module/post/domain/usecase/fetch_post_usecase";
import PostController from "@/module/post/controller/post_controller";
import deletePostRepository from "@/module/post/data/repository/delete_post_repository";
import deletePostUseCase from "@/module/post/domain/usecase/delete_post_usecase";
import createPostUseCase from "@/module/post/domain/usecase/create_post_usecase";
import createPostRepository from "@/module/post/data/repository/create_post_repository";
import changePostRepository from "@/module/post/data/repository/change_post_repository";
import changePostUseCase from "@/module/post/domain/usecase/change_post_usecase";
import fetchCategoryRepository from "@/module/category/data/repository/fetch_category_repository";
import deleteCategoryRepository from "@/module/category/data/repository/delete_category_repository";
import fetchCategoryUseCase from "@/module/category/domain/usecase/fetch_category_usecase";
import deleteCategoryUseCase from "@/module/category/domain/usecase/delete_category_usecase";
import createCategoryRepository from "@/module/category/data/repository/create_category_repository";
import createCategoryUseCase from "@/module/category/domain/usecase/create_category_usecase";
import changeCategoryRepository from "@/module/category/data/repository/change_category_repository";
import changeCategoryUseCase from "@/module/category/domain/usecase/change_category_usecase";
import CategoryController from "@/module/category/controller/category_controller";
import fetchTagRepository from "@/module/tag/data/repository/fetch_tag_repository";
import deleteTagRepository from "@/module/tag/data/repository/delete_tag_repository";
import fetchTagUseCase from "@/module/tag/domain/usecase/fetch_tag_usecase";
import deleteTagUseCase from "@/module/tag/domain/usecase/delete_tag_usecase";
import createTagRepository from "@/module/tag/data/repository/create_tag_repository";
import createTagUseCase from "@/module/tag/domain/usecase/create_tag_usecase";
import changeTagRepository from "@/module/tag/data/repository/change_tag_repository";
import changeTagUseCase from "@/module/tag/domain/usecase/change_tag_usecase";
import TagController from "@/module/tag/controller/tag_controller";
import fetchPublicatedPostsRepository from "@/module/post/data/repository/fetch_publicated_post_repository";
import fetchHighlightedPostsRepository from "@/module/post/data/repository/fetch_highlighted_post_repository";
import fetchDraftPostsRepository from "@/module/post/data/repository/fetch_draft_post_repository";
import fetchPublicatedPostsUseCase from "@/module/post/domain/usecase/fetch_publicated_post_usecase";
import fetchDraftPostsUseCase from "@/module/post/domain/usecase/fetch_draft_post_usecase";
import fetchHighlightedPostsUseCase from "@/module/post/domain/usecase/fetch_highlighted_post_usecase";
import fetchLeadRepository from "@/module/lead/data/repository/fetch_lead_repository";
import deleteLeadRepository from "@/module/lead/data/repository/delete_lead_repository";
import fetchLeadsUseCase from "@/module/lead/domain/usecase/fetch_lead_usecase";
import deleteLeadUseCase from "@/module/lead/domain/usecase/delete_lead_usecase";
import LeadController from "@/module/lead/controller/lead_controller";
import IndicatorController from "@/module/indicator/controller/indicator_controller";
import fetchSuccessHistoryPostsUseCase from "@/module/post/domain/usecase/fetch_success_history_post_usecase";
import fetchSuccessHistoryPostsRepository from "@/module/post/data/repository/fetch_success_history_post_repository";
import fetchMostLikedPostsRepository from "@/module/post/data/repository/fetch_most_liked_post_repository";
import fetchMostLikedPostsUseCase from "@/module/post/domain/usecase/fetch_most_liked_post_usecase";
import fetchMostSharedPostsUseCase from "@/module/post/domain/usecase/fetch_most_shared_post_usecase";
import fetchMostSharedPostsRepository from "@/module/post/data/repository/fetch_most_shared_post_repository";
import fetchVacancyRepository from "@/module/vacancy/data/repository/fetch_vacancy_repository";
import deleteVacancyRepository from "@/module/vacancy/data/repository/delete_vacancy_repository";
import fetchVacancyUseCase from "@/module/vacancy/domain/usecase/fetch_vacancy_usecase";
import deleteVacancyUseCase from "@/module/vacancy/domain/usecase/delete_vacancy_usecase";
import createVacancyRepository from "@/module/vacancy/data/repository/create_vacancy_repository";
import createVacancyUseCase from "@/module/vacancy/domain/usecase/create_vacancy_usecase";
import changeVacancyRepository from "@/module/vacancy/data/repository/change_vacancy_repository";
import changeVacancyUseCase from "@/module/vacancy/domain/usecase/change_vacancy_usecase";
import VacancyController from "@/module/vacancy/controller/vacancy_controller";


const loginRepositoryImpl = loginRepostory(axosInstance)
const loginUseCaseImpl = loginUseCase(loginRepositoryImpl)

//Home
const fetchAccounPermissionsRepositoryImpl = fetchAccounPermissionsRepository(axosInstance)
const fetchAccountPermissionsUseCaseImpl = fetchAccountPermissionsUseCase(fetchAccounPermissionsRepositoryImpl)

//User
const fetchUserRepositoryImpl = fetchUserRepository(axosInstance)
const deleteUserRepositoryImpl = deleteUserRepository(axosInstance)
const fetchUserUseCaseImpl = fetchUserUseCase(fetchUserRepositoryImpl)
const deleteUserUseCaseImpl = deleteUserUseCase(deleteUserRepositoryImpl)
const createUserRepositoryImpl = createUserRepository(axosInstance)
const createUserUseCaseImpl = createUserUseCase(createUserRepositoryImpl)
const changeUserRepositoryImpl = changeUserRepository(axosInstance)
const changeUserUseCaseImpl = changeUserUseCase(changeUserRepositoryImpl)

//Lead
const fetchLeadRepositoryImpl = fetchLeadRepository(axosInstance)
const deleteLeadRepositoryImpl = deleteLeadRepository(axosInstance)
const fetchLeadUseCaseImpl = fetchLeadsUseCase(fetchLeadRepositoryImpl)
const deleteLeadUseCaseImpl = deleteLeadUseCase(deleteLeadRepositoryImpl)


//Post
const fetchPostsRepositoryImpl = fetchPostsRepository(axosInstance)
const fetchPostsUseCaseImpl = fetchPostsUseCase(fetchPostsRepositoryImpl)
const deletePostRepositoryImpl = deletePostRepository(axosInstance)
const deletePostUseCaseImpl = deletePostUseCase(deletePostRepositoryImpl)
const createPostRepositoryImpl = createPostRepository(axosInstance)
const createPostUseCaseImpl = createPostUseCase(createPostRepositoryImpl)
const changePostRepositoryImpl = changePostRepository(axosInstance)
const changePostUseCaseImpl = changePostUseCase(changePostRepositoryImpl)
const fetchPublicatedPostRepositoryImpl = fetchPublicatedPostsRepository(axosInstance)
const fetchHighlightedPostRepositoryImpl = fetchHighlightedPostsRepository(axosInstance)
const fetchSuccessHistoryRepositoryImpl = fetchSuccessHistoryPostsRepository(axosInstance)
const fetchMostLikedRepositoryImpl = fetchMostLikedPostsRepository(axosInstance)
const fetchMostSharedRepositoryImpl = fetchMostSharedPostsRepository(axosInstance)
const fetchDraftPostRepositoryImpl = fetchDraftPostsRepository(axosInstance)
const fetchPublicatedPostUseCaseImpl = fetchPublicatedPostsUseCase(fetchPublicatedPostRepositoryImpl)
const fetchDraftPostUseCaseImpl = fetchDraftPostsUseCase(fetchDraftPostRepositoryImpl)
const fetchHighlightedPostUseCaseImpl = fetchHighlightedPostsUseCase(fetchHighlightedPostRepositoryImpl)
const fetchSuccessHistoryPostUseCaseImpl = fetchSuccessHistoryPostsUseCase(fetchSuccessHistoryRepositoryImpl)
const fetchMostLikedPostUseCaseImpl = fetchMostLikedPostsUseCase(fetchMostLikedRepositoryImpl)
const fetchMostSharedPostUseCaseImpl = fetchMostSharedPostsUseCase(fetchMostSharedRepositoryImpl)

//Category
const fetchCategoryRepositoryImpl = fetchCategoryRepository(axosInstance)
const deleteCategoryRepositoryImpl = deleteCategoryRepository(axosInstance)
const fetchCategoryUseCaseImpl = fetchCategoryUseCase(fetchCategoryRepositoryImpl)
const deleteCategoryUseCaseImpl = deleteCategoryUseCase(deleteCategoryRepositoryImpl)
const createCategoryRepositoryImpl = createCategoryRepository(axosInstance)
const createCategoryUseCaseImpl = createCategoryUseCase(createCategoryRepositoryImpl)
const changeCategoryRepositoryImpl = changeCategoryRepository(axosInstance)
const changeCategoryUseCaseImpl = changeCategoryUseCase(changeCategoryRepositoryImpl)


//Tags
const fetchTagRepositoryImpl = fetchTagRepository(axosInstance)
const deleteTagRepositoryImpl = deleteTagRepository(axosInstance)
const fetchTagUseCaseImpl = fetchTagUseCase(fetchTagRepositoryImpl)
const deleteTagUseCaseImpl = deleteTagUseCase(deleteTagRepositoryImpl)
const createTagRepositoryImpl = createTagRepository(axosInstance)
const createTagUseCaseImpl = createTagUseCase(createTagRepositoryImpl)
const changeTagRepositoryImpl = changeTagRepository(axosInstance)
const changeTagUseCaseImpl = changeTagUseCase(changeTagRepositoryImpl)

//Vacancy
const fetchVacancyRepositoryImpl = fetchVacancyRepository(axosInstance)
const deleteVacancyRepositoryImpl = deleteVacancyRepository(axosInstance)
const fetchVacancyUseCaseImpl = fetchVacancyUseCase(fetchVacancyRepositoryImpl)
const deleteVacancyUseCaseImpl = deleteVacancyUseCase(deleteVacancyRepositoryImpl)
const createVacancyRepositoryImpl = createVacancyRepository(axosInstance)
const createVacancyUseCaseImpl = createVacancyUseCase(createVacancyRepositoryImpl)
const changeVacancyRepositoryImpl = changeVacancyRepository(axosInstance)
const changeVacancyUseCaseImpl = changeVacancyUseCase(changeVacancyRepositoryImpl)

const dashboardController = (context: any) => new DashboardController(
    context,
)

const defaultController = (context: any) => new DefaultController(
    context
);

const loginController = (context: any) => new LoginController(
    context,
    loginUseCaseImpl
)

const homeController = (context: any) => new HomeController(
    context,
    fetchAccountPermissionsUseCaseImpl,
);

const userController = (context: any) => new UserController(
    context,
    fetchUserUseCaseImpl,
    deleteUserUseCaseImpl,
    createUserUseCaseImpl,
    changeUserUseCaseImpl,
)

const categoryController = (context: any) => new CategoryController(
    context,
    fetchCategoryUseCaseImpl,
    deleteCategoryUseCaseImpl,
    createCategoryUseCaseImpl,
    changeCategoryUseCaseImpl,
)

const tagController = (context: any) => new TagController(
    context,
    fetchTagUseCaseImpl,
    deleteTagUseCaseImpl,
    createTagUseCaseImpl,
    changeTagUseCaseImpl,
)

const vacancyController = (context: any) => new VacancyController(
    context,
    fetchVacancyUseCaseImpl,
    deleteVacancyUseCaseImpl,
    createVacancyUseCaseImpl,
    changeVacancyUseCaseImpl,
)

const postController = (context: any) => new PostController(
    context,
    fetchPostsUseCaseImpl,
    deletePostUseCaseImpl,
    fetchPublicatedPostUseCaseImpl,
    fetchDraftPostUseCaseImpl,
    fetchHighlightedPostUseCaseImpl,
)

const postEditorController = (context: any) => new PostEditorController(
    context,
    createPostUseCaseImpl,
    changePostUseCaseImpl,
    fetchCategoryUseCaseImpl,
    fetchTagUseCaseImpl,
    fetchPostsUseCaseImpl
)

const leadController = (context: any) => new LeadController(
    context,
    fetchLeadUseCaseImpl,
    deleteLeadUseCaseImpl
)

const indicatorController = (context: any) => new IndicatorController(
    context,
    fetchSuccessHistoryPostUseCaseImpl,
    fetchMostLikedPostUseCaseImpl,
    fetchMostSharedPostUseCaseImpl,
    fetchLeadUseCaseImpl
)

export {
    defaultController,
    loginController,
    homeController,
    userController,
    dashboardController,
    postEditorController,
    postController,
    categoryController,
    tagController,
    leadController,
    indicatorController,
    vacancyController
}

