<template>
  <div class="mx-5">
    <v-row justify="center">
      <v-col>
        <v-toolbar elevation="0">
          <v-icon @click="navigation.navigate(0)">mdi-arrow-left</v-icon>
          <v-spacer></v-spacer>
          <v-text-field
            class="mt-8"
            solo
            single-line
            placeholder="Pesquisar..."
            dense
            v-model="controller.search"
          ></v-text-field>
          <v-select
            style="max-width: 200px"
            class="mt-8 mx-2"
            solo
            dense
            single-line
            item-text="name"
            item-value="id"
            :items="controller.optionFilter"
            v-model="controller.filter"
          >

          </v-select>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="navigation.navigate(3)"
          >
            <v-icon left>mdi-plus</v-icon> novo
          </v-btn>
        </v-toolbar>
      </v-col>

    </v-row>
    <v-row>
      <v-col
        class="my-4"
        lg="4"
        v-for="(post, index) in controller.postPagination.items"
        :key="index"
      >
        <v-card>
          <v-card-text class="ma-0 pa-0">
            <v-row justify="center">
              <v-col
                class="ma-0 py-0"
                cols="5"
              >
                <v-img
                  cover
                  height="100%"
                  :src="post.assetPath"
                ></v-img>
              </v-col>
              <v-col class="pr-5">
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      icon
                      fab
                      small
                      @click="controller.editPost(post)"
                    >
                      <v-icon
                        small
                        color="blue"
                      >mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="hadPermission(1, 3)"
                      icon
                      fab
                      small
                      @click="controller.deletePost(post.id)"
                    >
                      <v-icon
                        small
                        color="red"
                      >mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h2>
                      {{ post.title }}
                    </h2>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    Criado por: <strong>{{ post.accountName }}</strong>
                    <br>
                    Criado em: <strong>{{ dayjs(post.publicationDate).format('DD/MM/YYYY HH:mm') }}</strong>
                    <br>
                    Última edição: <strong>{{ dayjs(post.lastUpdate).format('DD/MM/YYYY HH:mm') }}</strong>
                    <br>
                    Categoria: <strong>{{ post.categoryName }}</strong>
                  </v-col>
                </v-row>
                <v-row
                  v-if="post.status"
                  justify="end"
                  align="end"
                >
                  <v-col
                    cols="12"
                    class="d-flex justify-end"
                  >
                    <div class="px-2">
                      <v-icon left>mdi-eye</v-icon> {{ post.status.views }}
                    </div>
                    <div class="px-2">
                      <v-icon>mdi-heart</v-icon> {{ post.status.likes }}
                    </div>
                    <div class="px-2">
                      <v-icon>mdi-share</v-icon> {{ post.status.shares }}
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { postController } from "@/di/di";
import _ from "lodash";

export default {
  data: (context) => ({
    controller: postController(context),
  }),
  mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
    "controller.filter": _.debounce(function () {
      this.controller.watchFilter();
    }, 500),
  },
};
</script>
