import { CreateVacancyRepository } from '../../data/repository/create_vacancy_repository';
import { Vacancy } from '../model/vacancy';

export interface CreateVacancyUseCase {
    (data: object): Promise<Vacancy>
}

const createVacancyUseCase = (repository: CreateVacancyRepository): CreateVacancyUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createVacancyUseCase;

