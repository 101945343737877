import { LoginUseCase } from '@/core/domain/usecase/login_usecase';
class LoginController {
    private context: any
    private loginUseCase: LoginUseCase
    public username?: string
    public password?: string
    public loading: boolean = false

    constructor(
        context: any,
        loginUseCase: LoginUseCase,
    ) {
        this.context = context
        this.loginUseCase = loginUseCase
    }

    async login() {
        if (this.context.$refs.form.validate()) {
            try {
                this.loading = true
                await this.loginUseCase(this.username!, this.password!)

                window.router.home()
                this.username = ""
                this.password = ""
                this.context.$refs.form?.resetValidation()
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false
            }
        }
    }

    logOut() {
        window.router.login()
    }
}

export default LoginController