import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import Handler from '@/core/domain/usecase/handler';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Category } from '../../domain/model/category';

export interface CreateCategoryRepository {
  (data: object): Promise<Category>
}

const createCategoryRepository = (axios: AxiosInstance): CreateCategoryRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/category`,
      data,
    )
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default createCategoryRepository

