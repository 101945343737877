import { Post } from './../domain/model/post';
// import EditorJS, { OutputData } from "@editorjs/editorjs";
// import Header from "@editorjs/header";
// import List from "@editorjs/list";
// import Embed from "@editorjs/embed";
// import Delimiter from "@editorjs/delimiter";
// import ImageTool from "@editorjs/image";
// import Table from "@editorjs/table";
// import Checklist from "@editorjs/checklist";
// import Warning from "@editorjs/warning";
// import InlineCode from "@editorjs/inline-code";
import axios from "axios";
import { baseUrl } from "@/constants";
// import Paragraph from 'editorjs-paragraph-with-alignment'
// import AnyButton from 'editorjs-button';
import { snackbar } from "@/core/controller/snackbar_controller";
import { FetchPostsUseCase } from "../domain/usecase/fetch_post_usecase";
import { PostPagination } from "../domain/model/post_pagination";
import { defaultModelPagination, defaultPagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { CreatePostUseCase } from '../domain/usecase/create_post_usecase';
import { ChangePostUseCase } from '../domain/usecase/change_post_usecase';
import { CategoryPagination } from '@/module/category/domain/model/category_pagination';
import { FetchCategoryUseCase } from '@/module/category/domain/usecase/fetch_category_usecase';
import { FetchTagUseCase } from '@/module/tag/domain/usecase/fetch_tag_usecase';

class PostEditorController {
    public editor: any = null
    public content: string = "Conteúdo do post aqui...."
    public title: string = ""
    public slug: string = ""
    public description = ""
    public image: string = ""
    public draft: boolean = true
    public highlighted: boolean = false
    public preview: boolean = false
    public isChange: boolean = false
    public categoryPagination: CategoryPagination = defaultModelPagination()
    public category: number = 0
    public tagPagination: CategoryPagination = defaultModelPagination()
    public tags: Array<number> = []
    public postPagination: PostPagination = defaultModelPagination()
    public relatedPosts: Array<number> = []

    private id: number = 0
    private options: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["name"] })
    private optionsPost: DataOptions = defaultPagination({ itemsPerPage: -1, sortBy: ["title"] })

    constructor(
        private context: any,
        private createPostUseCase: CreatePostUseCase,
        private changePostUseCase: ChangePostUseCase,
        private fetchCategoryUseCase: FetchCategoryUseCase,
        private fetchTagUseCase: FetchTagUseCase,
        private fetchPostUseCase: FetchPostsUseCase
    ) { }

    async save() {
        try {
            if (!this.category) {
                snackbar.show({ message: "Selecione a categoria do post!" })
                return
            }

            if (this.isChange) {
                await this.changePostUseCase(this.id, {
                    title: this.title,
                    content: this.content,
                    draft: this.draft,
                    slug: this.slug,
                    assetPath: this.image,
                    categoryID: this.category,
                    description: this.description,
                    highlighted: this.highlighted,
                    tags: this.tags,
                    relatedPosts: this.relatedPosts
                })
                window.navigation.navigate(1)
                return
            }

            await this.createPostUseCase({
                title: this.title,
                content: this.content,
                draft: this.draft,
                slug: this.slug,
                assetPath: this.image,
                categoryID: this.category,
                description: this.description,
                highlighted: this.highlighted,
                tags: this.tags,
                relatedPosts: this.relatedPosts
            })

            window.navigation.navigate(1)
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }


        // await axios.post(baseUrl + "/post", , {
        //     headers: {
        //         "Content-Type": "application/form-data",
        //         "Authorization": `Bearer ${jwtAuthToken.token}`
        //     }
        // })

        // this.content = await this.editor.save()

        // if (this.content) {
        //     for (let block of this.content?.blocks) {
        //         if (block.type === 'paragraph') {
        //             this.description += block.data.text.replace(/&nbsp;/g, '');
        //         }
        //     }
        //     for (let block of this.content?.blocks) {
        //         if (block.type === 'header') {
        //             this.title = block.data.text.replace(/&nbsp;/g, '');
        //             break
        //         }
        //     }

        //     for (let block of this.content?.blocks) {
        //         if (block.type === 'image') {
        //             this.image = block.data.file.url
        //             break
        //         }
        //     }
        // } else {
        //     this.description = ""
        // }

        // console.log(this.title);
        // console.log(this.description);
        // console.log(this.slug);
        // console.log(this.image);
        // console.log(this.content);


    }

    watchTitle() {
        this.slug = this.title.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
    }

    async change(event: any) {
        try {
            const auth: string = localStorage.getItem('auth') ?? ''
            const jwtAuthToken = JSON.parse(auth)
            var formData = new FormData();
            formData.append("image", event.target.files[0]);
            const response = await axios.post(baseUrl + "/asset", formData, {
                headers: {
                    "Content-Type": "application/form-data",
                    "Authorization": `Bearer ${jwtAuthToken.token}`
                }
            })

            this.image = response.data.url;
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    uploadImage() {
        const inputFile = document.getElementById('input-file')
        inputFile?.click()
    }

    async mounted() {
        this.categoryPagination = await this.fetchCategoryUseCase(this.options, "")
        this.tagPagination = await this.fetchTagUseCase(this.options, "")
        this.postPagination = await this.fetchPostUseCase(this.optionsPost, "")

        const post: Post = this.context.$attrs.params

        if (post) {
            this.title = post.title
            this.image = post.assetPath
            this.description = post.description
            this.content = post.content
            this.slug = post.slug
            this.isChange = true
            this.id = post.id
            this.draft = post.draft
            this.highlighted = post.highlighted
            this.category = post.categoryID
            this.tags = post.tags.map((tag) => tag.tagID)
            this.relatedPosts = post.relatedPosts.map((related) => related.id)
        }

        // function OnInput() {
        //     this.style.height = "auto";
        //     this.style.height = (this.scrollHeight) + "px";
        // }

        // try {
        //     this.postPagination = await this.fetchPostsUseCase(this.postOptions, "")
        // } catch (error: any) {
        //     snackbar.show({ message: error.toString() })
        // }

        // let that = this

        // this.editorSettings = {
        //     readOnly: false,
        //     theme: 'bubble',

        //     modules: {
        //         imageDrop: true,
        //         ImageResize: {
        //             modules: ['Resize', 'DisplaySize', 'Toolbar']
        //         }
        //     }
        // };

        // this.editor = new EditorJS({
        //     placeholder: "Vamos escrever alguma história",
        //     holder: "editorjs",
        //     tools: {
        //         inlineCode: {
        //             class: InlineCode,
        //             shortcut: "CMD+SHIFT+M",
        //         },
        //         warning: {
        //             class: Warning,
        //             inlineToolbar: true,
        //             config: {
        //                 titlePlaceholder: "Title",
        //                 messagePlaceholder: "Message",
        //             },
        //         },
        //         checklist: {
        //             class: Checklist,
        //             inlineToolbar: true,
        //         },
        //         paragraph: {
        //             class: Paragraph,
        //             inlineToolbar: true,
        //           },
        //         table: {
        //             class: Table,
        //         },
        //         AnyButton: {
        //             class: AnyButton,
        //             inlineToolbar: false,
        //             config:{
        //               css:{
        //                 "btnColor": "btn--gray",
        //               }
        //             }
        //           },
        //         image: {
        //             class: ImageTool,
        //             config: {
        //                 // endpoints: {
        //                 //     byFile: "https://desenvapi.cgisoftware.com.br/image", // Your backend file uploader endpoint
        //                 //     byUrl: "https://desenvapi.cgisoftware.com.br/image", // Your endpoint that provides uploading by Url
        //                 // },
        //                 uploader: {
        //                     uploadByFile(file) {
        //                         const auth: string = localStorage.getItem('auth') ?? ''
        //                         const jwtAuthToken = JSON.parse(auth)
        //                         var formData = new FormData();
        //                         formData.append("image", file);
        //                         return axios.post(baseUrl + "/asset", formData, {
        //                             headers: {
        //                                 "Content-Type": "application/form-data",
        //                                 "Authorization": `Bearer ${jwtAuthToken.token}`
        //                             }
        //                         }).then((response) => {
        //                             setTimeout(() => {
        //                                 const caption = document.getElementsByClassName("cdx-input image-tool__caption")
        //                                 while(caption.length > 0){
        //                                     caption?.[0]?.parentNode?.removeChild(caption[0]);
        //                                 }

        //                             }, 200)
        //                             return {
        //                                 success: 1,
        //                                 file: {
        //                                     url: response.data.url,
        //                                 }
        //                             };
        //                         });
        //                     },
        //                     uploadByUrl(url) {
        //                         const auth: string = localStorage.getItem('auth') ?? ''
        //                         const jwtAuthToken = JSON.parse(auth)
        //                         return axios.post(baseUrl + "/asset/url", { url: url }, {
        //                             headers: {
        //                                 "Content-Type": "application/json",
        //                                 "Authorization": `Bearer ${jwtAuthToken.token}`
        //                             }
        //                         }).then((response) => {
        //                             setTimeout(() => {
        //                                 const caption = document.getElementsByClassName("cdx-input image-tool__caption")
        //                                 while(caption.length > 0){
        //                                     caption?.[0]?.parentNode?.removeChild(caption[0]);
        //                                 }

        //                             }, 200)
        //                             return {
        //                                 success: 1,
        //                                 file: {
        //                                     url: response.data.url,
        //                                 }
        //                             };
        //                         });
        //                     },
        //                 },
        //             },
        //         },
        //         header: {
        //             class: Header,
        //             inlineToolbar: true,
        //         },
        //         list: {
        //             class: List,
        //             inlineToolbar: true,
        //         },
        //         embed: Embed,
        //         delimiter: Delimiter,
        //     },
        //     data: this.content,
        //     // onChange(_) {
        //     //     that.save()
        //     // }
        // });

        // await this.editor.isReady;
    }

    handleImageAdded(file, Editor, cursorLocation, resetUploader) {

        console.log(file, Editor, cursorLocation, resetUploader);

        // An example of using FormData
        // NOTE: Your key could be different such as:
        // formData.append('file', file)

        // var formData = new FormData();
        // formData.append("image", file);

        // axios({
        //     url: "https://fakeapi.yoursite.com/images",
        //     method: "POST",
        //     data: formData
        // })
        //     .then(result => {
        //         const url = result.data.url; // Get url from response
        //         Editor.insertEmbed(cursorLocation, "image", url);
        //         resetUploader();
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     });
    }
}

export default PostEditorController