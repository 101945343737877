<template>
  <div class="mx-5">
    <v-row justify="center">
      <v-col>
        <v-toolbar elevation="0">
          <v-icon @click="navigation.navigate(0)">mdi-arrow-left</v-icon>
          <v-spacer></v-spacer>
          <v-text-field
            class="mt-8"
            solo
            single-line
            placeholder="Pesquisar..."
            dense
            v-model="controller.search"
          ></v-text-field>
        </v-toolbar>
      </v-col>

    </v-row>
    <v-row>
      <v-col
        class="my-4"
        lg="4"
        v-for="(lead, index) in controller.leadPagination.items"
        :key="index"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                class="pr-5"
                cols="10"
              >
                <div v-html="lead.description"></div>
                <div>Data da solicitação: <strong>{{ dayjs(lead.createdDate).format("DD/MM/YYYY HH:mm") }}</strong></div>
              </v-col>
              <v-col class="text-right">
                <v-btn
                  cols="2"
                  icon
                  fab
                  small
                  @click="controller.deleteLead(lead.id)"
                >
                  <v-icon
                    small
                    color="red"
                  >mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { leadController } from "@/di/di";
export default {
  data: (context) => ({
    controller: leadController(context),

    emailRules: [
      (v) => !!v || "Preencha o campo",
      (v) => /.+@.+/.test(v) || "Deve ser um E-mail",
    ],
  }),
  mounted() {
    this.controller.paginate();
  },
  watch: {
    "controller.search": _.debounce(function () {
      this.controller.watchSearch();
    }, 500),
  },
};
</script>