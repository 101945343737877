export interface User {
    accountGroupID: number;
    active: boolean;
    companyID: number;
    companyName: string;
    email: string;
    id: number;
    login: string;
    name: string;
    password: string;
}


const en: any = {
    id: 1,
    accountGroupID: 7,
    active: 8,
    companyID: 9,
    email: 3,
    login: 2,
    name: 4,
    password: 5,
    companyName: 10
};
export { en };
