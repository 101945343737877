import { CreateTagRepository } from '../../data/repository/create_tag_repository';
import { Tag } from '../model/tag';

export interface CreateTagUseCase {
    (data: object): Promise<Tag>
}

const createTagUseCase = (repository: CreateTagRepository): CreateTagUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createTagUseCase;

