import { CreatePostRepository } from '../../data/repository/create_post_repository';
import { Post } from '../model/post';

export interface CreatePostUseCase {
    (data: object): Promise<Post>
}

const createPostUseCase = (repository: CreatePostRepository): CreatePostUseCase => async (data: Object) => {
    try {
        return await repository(data);
    } catch (error) {
        throw error
    }
}

export default createPostUseCase;

