import { FetchSuccessHistoryPostsUseCase } from './../../post/domain/usecase/fetch_success_history_post_usecase';
import { defaultModelPagination, defaultPagination } from './../../../core/domain/model/pagination';
import { DataOptions } from 'vuetify';
import { PostPagination } from './../../post/domain/model/post_pagination';
import { FetchHighlightedPostsUseCase } from './../../post/domain/usecase/fetch_highlighted_post_usecase';
import { snackbar } from '@/core/controller/snackbar_controller';
import * as echarts from 'echarts';
import { Post } from '@/module/post/domain/model/post';
import { FetchMostLikedPostsUseCase } from '@/module/post/domain/usecase/fetch_most_liked_post_usecase';
import { FetchMostSharedPostsUseCase } from '@/module/post/domain/usecase/fetch_most_shared_post_usecase';
import { FetchLeadsUseCase } from '@/module/lead/domain/usecase/fetch_lead_usecase';
import { hadPermission } from '@/core/domain/model/permission';

class IndicatorController {
    private top3Chart: echarts.ECharts | null = null
    private top3ChartLiked: echarts.ECharts | null = null
    private top3ChartShared: echarts.ECharts | null = null
    public top10PostsLiked: Array<any> = []
    public lastLeads: Array<any> = []
    private options: DataOptions = defaultPagination({itemsPerPage: 10, sortBy: ["createdDate"], sortDesc: [true]}) 

    constructor(
        private context: any,
        private fetchSuccessHistoryPostsUseCase: FetchSuccessHistoryPostsUseCase,
        private fetchMostLikedPostsUseCase: FetchMostLikedPostsUseCase,
        private fetchMostSharedPostsUseCase: FetchMostSharedPostsUseCase,
        private fetchLeadsUseCase: FetchLeadsUseCase
    ) { }

    async mounted() {
        try {
            const [posts, liked, shared, leads] = await Promise.all([
                this.fetchSuccessHistoryPostsUseCase(),
                this.fetchMostLikedPostsUseCase(),
                this.fetchMostSharedPostsUseCase(),
                hadPermission(2, 4) ? this.fetchLeadsUseCase(this.options, "") : Promise.resolve(defaultModelPagination())
            ])
            this.top3ChartGenerate(posts)
            this.top3ChartLikedGenerate(liked)
            this.top3ChartSharedGenerate(shared)
            this.top10PostsLiked = liked
            this.lastLeads = leads.items
        } catch (error: any) {
            snackbar.show({ message: error.toString() })
        }
    }

    private top3ChartGenerate(posts: Post[]) {
        var chartDom = document.getElementById('top3_chart');
        console.log(chartDom);

        if (chartDom != null) {
            this.top3Chart = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');

            const option = {
                // title: {
                //     text: 'Top 3 posts mais acessados',
                //     //subtext: 'Fake Data',
                //     left: 'center'
                // },
                grid: {
                    top: 0
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    left: 'center',
                    bottom: 10,
                },
                series: [
                    {
                        name: 'Post',
                        type: 'pie',
                        radius: '60%',
                        data: posts.map((post) => {
                            return {
                                value: post.status.views,
                                name: post.title
                            }
                        }),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            this.top3Chart.setOption(option)
        }
    }


    private top3ChartLikedGenerate(posts: Post[]) {
        var chartDom = document.getElementById('top3_chart_liked');
        console.log(chartDom);

        if (chartDom != null) {
            this.top3ChartLiked = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');

            const option = {
                // title: {
                //     text: 'Top 3 posts mais acessados',
                //     //subtext: 'Fake Data',
                //     left: 'center'
                // },
                grid: {
                    top: 0
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    left: 'center',
                    bottom: 10,
                },
                series: [
                    {
                        name: 'Post',
                        type: 'pie',
                        radius: '60%',
                        data: posts.slice(0, 3).map((post) => {
                            return {
                                value: post.status.likes,
                                name: post.title
                            }
                        }),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            this.top3ChartLiked.setOption(option)
        }
    }


    private top3ChartSharedGenerate(posts: Post[]) {
        var chartDom = document.getElementById('top3_chart_shared');
        console.log(chartDom);

        if (chartDom != null) {
            this.top3ChartShared = echarts.init(chartDom!, this.context.$vuetify.theme.isDark ? 'dark' : 'light');

            const option = {
                // title: {
                //     text: 'Top 3 posts mais acessados',
                //     //subtext: 'Fake Data',
                //     left: 'center'
                // },
                grid: {
                    top: 0
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    left: 'center',
                    bottom: 10,
                },
                series: [
                    {
                        name: 'Post',
                        type: 'pie',
                        radius: '60%',
                        data: posts.slice(0, 3).map((post) => {
                            return {
                                value: post.status.shares,
                                name: post.title
                            }
                        }),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            this.top3ChartShared.setOption(option)
        }
    }
}

export default IndicatorController