export const headers = [
    {
        text: 'Descrição',
        align: 'start',
        sortable: true,
        value: 'name',
    },
    {
        text: 'Ativa',
        align: 'center',
        sortable: true,
        value: 'active',
    },
    {
        text: 'Ações',
        value: 'actions',
        align: 'right',
        sortable: false
    }
]
