import { DeleteTagRepository } from '../../data/repository/delete_tag_repository';
import { Tag } from '../model/tag';

export interface DeleteTagUseCase {
    (id: number): Promise<Tag>
}

const deleteTagUseCase = (repository: DeleteTagRepository): DeleteTagUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deleteTagUseCase;