import { getAccountGroupID } from '@/core/domain/model/jwtAuthToken';
import { CategoryPagination } from '../domain/model/category_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Category } from '../domain/model/category';
import { snackbar } from '@/core/controller/snackbar_controller';
import { FetchCategoryUseCase } from '../domain/usecase/fetch_category_usecase';
import { DeleteCategoryUseCase } from '../domain/usecase/delete_category_usecase';
import { CreateCategoryUseCase } from '../domain/usecase/create_category_usecase';
import { ChangeCategoryUseCase } from '../domain/usecase/change_category_usecase';
import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';

class CategoryController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public isAdmin: boolean = false
  public categoryPagination: CategoryPagination = defaultModelPagination()

  public options: DataOptions = defaultPagination({})

  public category: Category = {
    id: 0, name: ""
  }

  public groupItems: Array<Object> = [
    { id: 2, group: "MODERADOR" },
  ]

  constructor(
    private context: any,
    private fetchCategoryUseCase: FetchCategoryUseCase,
    private deleteCategoryUseCase: DeleteCategoryUseCase,
    private createCategoryUseCase: CreateCategoryUseCase,
    private changeCategoryUseCase: ChangeCategoryUseCase
  ) { }

  open() {
    if (getAccountGroupID() == 1) {
      this.groupItems.unshift({ id: 1, group: "ADMINISTRADOR" },)
    }
    this.flagOption = "create"
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.categoryPagination = await this.fetchCategoryUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createCategoryUseCase(this.category)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeCategoryUseCase(this.category.id, this.category)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Category) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red' })
      if (confirm) {
        await this.deleteCategoryUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: Category) {
    this.dialog = true
    this.flagOption = "change"
    this.category = { ...item }
  }
}

export default CategoryController