import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import Handler from '@/core/domain/usecase/handler';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Tag } from '../../domain/model/tag';

export interface CreateTagRepository {
  (data: object): Promise<Tag>
}

const createTagRepository = (axios: AxiosInstance): CreateTagRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/tag`,
      data,
    )
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default createTagRepository

