import { DataOptions } from "vuetify";


interface Page {
    page?: number;
    itemsPerPage?: number;
    groupBy?: string[];
    sortBy?: string[];
    groupDesc?: boolean[];
    multiSort?: boolean;
    mustSort?: boolean;
    sortDesc?: boolean[];
}

export interface Pagination {
    itemsPerPage: number;
    page: number;
    sort: string;
    descending: string;
    search: string;
    status?: number | 0;
}

export interface PaginationDate {
    itemsPerPage: number;
    page: number;
    sort: string;
    descending: string;
    search: string;
    startDate: string | undefined;
    endDate: string | undefined
}

export const visibilityStatus = [{
    id: 0,
    name: 'Todos',

}, {
    id: 1,
    name: 'Ativos',
},
{

    id: 2,
    name: 'Inativos'
},
{

    id: 3,
    name: 'Por equipamento'
},
{

    id: 4,
    name: 'Sem equipamento'
}]

export const itemStatus = [{
    id: 1,
    name: 'Ativo',
},
{

    id: 2,
    name: 'Inativo'
}]


export const defaultPagination = ({
    page = 1,
    itemsPerPage = 40,
    groupBy = [],
    sortBy = [],
    groupDesc = [],
    multiSort = false,
    mustSort = false,
    sortDesc = [],
}: Page): DataOptions => {
    return {
        page: page,
        itemsPerPage: itemsPerPage,
        groupBy: groupBy,
        sortBy: sortBy,
        groupDesc: groupDesc,
        multiSort: multiSort,
        mustSort: mustSort,
        sortDesc: sortDesc
    }
}

export const defaultModelPagination = () => {
    return {
        total: 0,
        items: []
    }
}

