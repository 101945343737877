import { getAccountGroupID } from '@/core/domain/model/jwtAuthToken';
import { FetchLeadsUseCase } from '../domain/usecase/fetch_lead_usecase';
import { DeleteLeadUseCase } from '../domain/usecase/delete_lead_usecase';
import { LeadPagination } from '../domain/model/lead_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Lead } from '../domain/model/lead';
import { snackbar } from '@/core/controller/snackbar_controller';

class LeadController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public isAdmin: boolean = false
  public leadPagination: LeadPagination = {
    total: 0,
    items: []
  }

  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: ["createdDate"],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: [true]
  }

  public lead: Lead = {
    id: 0, pageFrom: "", description: "", createdDate: "  "
  }


  constructor(
    private context: any,
    private fetchLeadsUseCase: FetchLeadsUseCase,
    private deleteLeadUseCase: DeleteLeadUseCase
  ) { }

  open() {
    this.flagOption = "create"
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.leadPagination = await this.fetchLeadsUseCase(this.options, this.search)   
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async deleteLead(id: number) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red' })
      if (confirm) {
        await this.deleteLeadUseCase(id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: Lead) {
    this.dialog = true
    this.flagOption = "change"
    this.lead = { ...item }
  }
}

export default LeadController