export interface Vacancy {
    id: number;
    name: string;
    formLink: string;
    active: boolean;
}


const en: any = {
    id: 1,
    name: 2,
    formLink: 3,
    active: 4
};
export { en };
