export interface Category {
    id: number;
    name: string;
}


const en: any = {
    id: 1,
    name: 2
};
export { en };
