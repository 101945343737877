import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import Handler from '@/core/domain/usecase/handler';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Vacancy } from '../../domain/model/vacancy';

export interface CreateVacancyRepository {
  (data: object): Promise<Vacancy>
}

const createVacancyRepository = (axios: AxiosInstance): CreateVacancyRepository => async (data: object) => {
  try {
    const response: AxiosResponse = await axios.post(`/vacancy`,
      data,
    )
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default createVacancyRepository

