import { DeletePostRepository } from '../../data/repository/delete_post_repository';
import { Post } from '../model/post';

export interface DeletePostUseCase {
    (id: number): Promise<Post>
}

const deletePostUseCase = (repository: DeletePostRepository): DeletePostUseCase => async (id: number) => {
    try {
        return await repository(id);
    } catch (error) {
        throw error
    }
}

export default deletePostUseCase;