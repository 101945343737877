import { User } from './../../domain/model/user';
import { UserPagination } from '../../domain/model/user_pagination';
import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { Pagination } from '@/core/domain/model/pagination';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteUserRepository {
    (id: number): Promise<User>
}

const deleteUserRepository = (axios: AxiosInstance): DeleteUserRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/account/${id}`)
        const user: User = response.data
        return user
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default deleteUserRepository