import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';
import { DeleteVacancyUseCase } from '../domain/usecase/delete_vacancy_usecase';
import { CreateVacancyUseCase } from '../domain/usecase/create_vacancy_usecase';
import { ChangeVacancyUseCase } from '../domain/usecase/change_vacancy_usecase';
import { FetchVacancyUseCase } from '../domain/usecase/fetch_vacancy_usecase';
import { VacancyPagination } from '../domain/model/vacancy_pagination';
import { snackbar } from '@/core/controller/snackbar_controller';
import { Vacancy } from '../domain/model/vacancy';
import { headers } from '../const/headers';
import { DataOptions } from 'vuetify';

class VacancyController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public isAdmin: boolean = false
  public vacancyPagination: VacancyPagination = defaultModelPagination()

  public options: DataOptions = defaultPagination({})

  public vacancy: Vacancy = {
    id: 0, name: "", formLink: "", active: true,
  }


  constructor(
    private context: any,
    private fetchVacancyUseCase: FetchVacancyUseCase,
    private deleteVacancyUseCase: DeleteVacancyUseCase,
    private createVacancyUseCase: CreateVacancyUseCase,
    private changeVacancyUseCase: ChangeVacancyUseCase
  ) { }

  open() {
    this.flagOption = "create"
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.vacancyPagination = await this.fetchVacancyUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createVacancyUseCase(this.vacancy)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeVacancyUseCase(this.vacancy.id, this.vacancy)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }

        this.vacancy = {
          id: 0, name: "", formLink: "", active: true,
        }
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      }
    }
  }

  async delete(item: Vacancy) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red' })
      if (confirm) {
        await this.deleteVacancyUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: Vacancy) {
    this.dialog = true
    this.flagOption = "change"
    this.vacancy = { ...item }
  }
}

export default VacancyController