import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
export interface RefreshRepository {
    (jwtAuthToken: JwtAuthToken): Promise<JwtAuthToken>
}

const refreshRepostory = (baseURL: string): RefreshRepository => async (jwtAuthToken: JwtAuthToken) => {
    try {
        const response: AxiosResponse = await axios.get(`${baseURL}/session`, {
            headers: {
                authorization: `Bearer ${jwtAuthToken.token} ${jwtAuthToken.refresh} ${jwtAuthToken.type}`
            }
        })

        const newJwtAuthToken: JwtAuthToken = response.data
        return newJwtAuthToken
    } catch (error) {
        throw error
    }
}

export default refreshRepostory