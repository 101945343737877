import { Post } from '@/module/post/domain/model/post';
import { FetchSuccessHistoryPostsRepository } from './../../data/repository/fetch_success_history_post_repository';
import { PostPagination } from '../model/post_pagination';
import { Pagination } from "@/core/domain/model/pagination";
import { DataOptions } from "vuetify";
import { en } from '../model/post';

export interface FetchSuccessHistoryPostsUseCase {
    (): Promise<Post[]>
}

const fetchSuccessHistoryPostsUseCase = (repository: FetchSuccessHistoryPostsRepository): FetchSuccessHistoryPostsUseCase => async () => {
    try {
        return await repository();
    } catch (error) {
        throw error
    }
}

export default fetchSuccessHistoryPostsUseCase;