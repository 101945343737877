import { getAccountGroupID } from '@/core/domain/model/jwtAuthToken';
import { TagPagination } from '../domain/model/tag_pagination';
import { DataOptions } from 'vuetify';
import { headers } from '../const/headers';
import { Tag } from '../domain/model/tag';
import { snackbar } from '@/core/controller/snackbar_controller';
import { FetchTagUseCase } from '../domain/usecase/fetch_tag_usecase';
import { DeleteTagUseCase } from '../domain/usecase/delete_tag_usecase';
import { CreateTagUseCase } from '../domain/usecase/create_tag_usecase';
import { ChangeTagUseCase } from '../domain/usecase/change_tag_usecase';
import { defaultModelPagination, defaultPagination } from '@/core/domain/model/pagination';

class TagController {
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public isAdmin: boolean = false
  public tagPagination: TagPagination = defaultModelPagination()

  public options: DataOptions = defaultPagination({})

  public tag: Tag = {
    id: 0, name: ""
  }

  public groupItems: Array<Object> = [
    { id: 2, group: "MODERADOR" },
  ]

  constructor(
    private context: any,
    private fetchTagUseCase: FetchTagUseCase,
    private deleteTagUseCase: DeleteTagUseCase,
    private createTagUseCase: CreateTagUseCase,
    private changeTagUseCase: ChangeTagUseCase
  ) { }

  open() {
    this.flagOption = "create"
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.tagPagination = await this.fetchTagUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createTagUseCase(this.tag)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeTagUseCase(this.tag.id, this.tag)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: Tag) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red' })
      if (confirm) {
        await this.deleteTagUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: Tag) {
    this.dialog = true
    this.flagOption = "change"
    this.tag = { ...item }
  }
}

export default TagController