interface Status {
    views: number
    likes: number
    shares: number
}

export interface Post {
    accountID: number;
    accountName: string;
    assetPath: string;
    categoryID: number;
    content: string;
    id: number;
    draft: boolean;
    lastUpdate: string;
    publicationDate: string;
    slug: string;
    title: string;
    description: string;
    highlighted: boolean;
    tags: Array<any>
    relatedPosts: Array<any>
    status: Status
}

const en: any = {
    id: 1,
    title: 2,
    content: 3,
    draft: 4,
    lastUpdate: 5,
    publicationDate: 6,
    slug: 7,
    categoryID: 8,
    assetPath: 9,
    accountID: 10,
    accountName: 11,
    description: 12,
    highlighted: 13,
};
export { en };
