import { Post } from '../../domain/model/post';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface DeletePostRepository {
    (id: number): Promise<Post>
}

const deletePostRepository = (axios: AxiosInstance): DeletePostRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/post/${id}`)
        const post: Post = response.data
        return post
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default deletePostRepository