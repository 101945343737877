import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 'sm'
  },
  theme: {
    themes: {
      light: {
        primary: '#00CE01',
        //primary: "#3F9A02",
        app_bar: "#066ee4",
        secondary: '#208A39',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        //background_screen: "#E0E1E6"
      },
      dark: {
        primary: "#00CE01",
        app_bar: "#066ee4",
        //background_info_card: "#121212"
        
        //nav: "#303030",
      },
    },
  },
    lang: {
      locales: { pt },
      current: 'pt',
    },
});



