import { FetchMostSharedPostsRepository } from '../../data/repository/fetch_most_shared_post_repository';
import { Post } from '@/module/post/domain/model/post';

export interface FetchMostSharedPostsUseCase {
    (): Promise<Post[]>
}

const fetchMostSharedPostsUseCase = (repository: FetchMostSharedPostsRepository): FetchMostSharedPostsUseCase => async () => {
    try {
        return await repository();
    } catch (error) {
        throw error
    }
}

export default fetchMostSharedPostsUseCase;