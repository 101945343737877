<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="termosController.termosDialog = false">
        <v-icon color="red">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <h1>TERMO E CONDIÇÕES GERAIS DE USO DA PLATAFORMA HYDRA</h1>
      <v-spacer></v-spacer>

      <br />

      <p>
        O ecossistema HYDRA, composto pelo conjunto de ferramentas e
        funcionalidades compreendidas em site e aplicativo, é fornecida pela
        GREEN NEXT AUTOMACAO LTDA. - ME, pessoa jurídica de direito privado,
        sociedade empresarial constituída segundo as leis da República
        Federativa do Brasil, inscrita no CPNJ/MF sob o nº. 40.203.201/0001-00,
        sediada na Rua Doutor Lázaro Ludovico Zamenhof, nº. 58, Bairro Lar
        Gaúcho - Rio Grande/RS, CEP 96.202-400, titular da propriedade
        intelectual e todos os direitos sobre o ecossistema HYDRA.
      </p>

      <h3>I. DA PLATAFORMA HYDRA</h3>

      <p>
        01. A plataforma do HYDRA é um sistema digital para gestão e controle
        dos sistemas instalados em campo (Hydra NVL e módulo receptor). A
        plataforma é uma interface digital que contém diversas funcionalidades
        que auxiliam o produtor na sua tomada de decisões.
      </p>
      <p>
        02. Dentre as funcionalidades da plataforma do HYDRA estão a
        disponibilização de: 
      </p>
              <span>
        &nbsp;&nbsp;&nbsp;&nbsp;a) Informações obtidas através dos equipamentos
        Hydra NVL; </span
      ><br />
            <span>
        &nbsp;&nbsp;&nbsp;&nbsp;b) Informações de bombas;</span
      ><br />
            <span>
        &nbsp;&nbsp;&nbsp;&nbsp;c) Dados de Levantes (Resumo de
        dados de bombas, aferição de custos); </span
      ><br />
            <span>
        &nbsp;&nbsp;&nbsp;&nbsp;d) Sistema de relatórios
        automático (Gerado através dos dados de bomba);  </span
      ><br />
            <span>
        &nbsp;&nbsp;&nbsp;&nbsp;e) Sistema de
        gerenciamento de equipamentos; </span
      ><br />
            <span>
        &nbsp;&nbsp;&nbsp;&nbsp;f) Sistema de gerenciamento de equipes e
        acessos ao aplicativo.</span
      ><br /><br />

      <p>
        03. O aplicativo “meu hydra” contém funcionalidades para a adição manual
        de medições em pontos de régua (mesmas do Hydra NVL) e também dados de
        bombas separadas por levante (locais que concentram as bombas de
        irrigação). Através do aplicativo de levantes o colaborador da fazenda,
        denominado “aguador”, ao se deslocar até um dos locais propostos, pode
        adicionar informações específicas para cada bomba que serão utilizadas
        nas aplicações presentes na plataforma do HYDRA.
      </p>

      <h3>II. DO ACEITE AO TERMO DE USO E CONDIÇÕES</h3>
      <p>
        04. O presente Termo e Condições de Uso estabelece todas as obrigações
        contratadas de livre e espontânea vontade, por tempo indeterminado,
        entre a GREEN NEXT e as pessoas físicas ou jurídicas, usuárias do site
        ou aplicativo de toda e qualquer uma das funcionalidades
        disponibilizadas pelo ecossistema HYDRA.
      </p>
      <p>
        05. Ao realizar o download e utilizar o aplicativo HYDRA o usuário
        aceita integralmente as presentes normas em suas obrigações e direitos e
        compromete-se a observá-las, sob o risco de aplicação das penalidades
        cabíveis pela desenvolvedora e proprietária do software.
      </p>
      <p>
        06. A aceitação do presente instrumento é imprescindível para o acesso e
        para a utilização de quaisquer serviços fornecidos através da plataforma
        HYDRA. Não concordando o usuário com qualquer uma das disposições neste
        instrumento contidas o usuário não deverá utilizar a plataforma, de modo
        que a liberalidade de uso é condicionada a aceitação integral de todas
        as condições aqui estabelecidas.
      </p>

      <h3>III. DO ACESSO E CADASTRO DO USUÁRIO</h3>

      <p>
        07. O acesso às funcionalidades da plataforma em qualquer de suas
        modalidades exigirá a realização do cadastro prévio do usuário e, a
        depender dos serviços ou produtos escolhidos, o pagamento de determinado
        valor.
      </p>

      <p>
        08. Ao se cadastrar o usuário deverá informar dados completos, recentes
        e válidos, sendo de sua exclusiva responsabilidade manter os referidos
        dados atualizados, bem como o usuário se compromete com a veracidade dos
        dados fornecidos.
      </p>

      <p>
        09. O usuário se compromete a não informar seus dados cadastrais e/ou de
        acesso à plataforma a terceiros, responsabilizando-se integralmente pelo
        uso que deles seja feito.
      </p>

      <p>
        10. Menores de 18 (dezoito) anos e aqueles que não possuírem plena
        capacidade civil deverão obter previamente o consentimento expresso de
        seus responsáveis legais para utilização da plataforma e dos serviços ou
        produtos, sendo de responsabilidade exclusiva dos mesmos o eventual
        acesso por menores de idade e por aqueles que não possuem plena
        capacidade civil sem a prévia autorização.
      </p>

      <p>
        11. Mediante a realização do cadastro o usuário declara e garante
        expressamente ser plenamente capaz, podendo exercer e usufruir
        livremente dos serviços e produtos.
      </p>

      <p>
        12. O usuário deverá fornecer um endereço de e-mail válido, através do
        qual o site realizará todas comunicações necessárias.
      </p>

      <p>
        13. Após a confirmação do cadastro, o usuário possuirá um login e uma
        senha pessoal, a qual assegura ao usuário o acesso individual à mesma.
        Desta forma, compete ao usuário exclusivamente a manutenção de referida
        senha de maneira confidencial e segura, evitando o acesso indevido às
        informações pessoais.
      </p>

      <p>
        14. Toda e qualquer atividade realizada com o uso da senha será de
        responsabilidade do usuário, que deverá informar prontamente a
        plataforma em caso de uso indevido da respectiva senha.
      </p>

      <p>
        15. Não será permitido ceder, vender, alugar ou transferir, de qualquer
        forma, a conta, que é pessoal e intransferível.
      </p>

      <p>
        16. Caberá ao usuário assegurar que o seu equipamento seja compatível
        com as características técnicas que viabilize a utilização da plataforma
        e dos serviços ou produtos.
      </p>

      <p>
        17. O usuário poderá, a qualquer tempo, requerer o cancelamento de seu
        cadastro junto ao site
        <a href="https://www.greennext.com.br/" target="_blank"
          >https://www.greennext.com.br</a
        >
        ou aplicativo da plataforma HYDRA. O seu descadastramento será realizado
        o mais rapidamente possível, desde que não sejam verificados débitos em
        aberto, na hipótese de utilização e contratação de qualquer um dos
        planos pagos pelo usuário.
      </p>

      <p>
        18. O usuário, ao aceitar os Termos e Política de Privacidade, autoriza
        expressamente a plataforma a coletar, usar, armazenar, tratar, ceder ou
        utilizar as informações derivadas do uso dos serviços, do site e
        quaisquer plataformas, incluindo todas as informações preenchidas pelo
        usuário no momento em que realizar ou atualizar seu cadastro, além de
        outras expressamente descritas na Política de Privacidade que deverá ser
        autorizada pelo usuário e observadas todas as normas decorrentes da Lei
        Geral de Proteção de Dados Pessoais, Lei nº. 13.709, de 14 de agosto de
        2018.
      </p>

      <h3>IV. DOS SERVIÇOS OU PRODUTOS</h3>

      <p>
        19. O ecossistema HYDRA através de site ou aplicativo poderá
        disponibilizar para o usuário um conjunto específico de funcionalidades
        e ferramentas para otimizar o uso dos serviços e produtos, e ainda,
        poderá ser comercializado pela plataforma os respectivos pacotes de
        funcionalidades por adesão através do aplicativo e/ou site.
      </p>

      <p>
        20. No ecossistema HYDRA os serviços ou produtos oferecidos estão
        descritos e apresentados com o maior grau de exatidão, contendo
        informações sobre suas funções, características, preço, prazos de
        vigência e entre outros dados.
      </p>

      <p>
        21. Antes de finalizar a compra sobre determinado pacote de
        funcionalidades, o usuário deverá se informar sobre as suas
        especificações e sobre a sua destinação.
      </p>

      <h3>V. DOS PREÇOS</h3>

      <p>
        22. O ecossistema disponibiliza pacotes gratuitos e pagos de acordo com
        a opção e escolha do usuário ao realizar o cadastro e acesso à
        plataforma HYDRA.
      </p>

      <p>
        23. Ao aderir aos planos e pacotes pagos o usuário será informado de
        todas as condições, prazos, valores, funcionalidades e limitações que
        implicarão na sua assinatura.
      </p>

      <p>
        24. A GREEN NEXT AUTOMACAO LTDA. - ME se reserva no direito de reajustar
        unilateralmente, a qualquer tempo, os valores dos pacotes, planos,
        serviços ou produtos sem consulta ou anuência prévia do usuário já
        cadastrado.
      </p>

      <p>
        25. Os valores aplicados são aqueles que estão em vigor no momento do
        pedido realizado pelo usuário.
      </p>

      <p>
        26. Na contratação de determinado plano ou pacote de serviços a
        plataforma poderá solicitar as informações financeiras do usuário, como
        CPF, endereço de cobrança e dados de cartões. Ao inserir referidos dados
        o usuário concorda que sejam cobrados, de acordo com a forma de
        pagamento que venha a ser escolhida, os preços então vigentes e
        informados quando da contratação. Referidos dados financeiros poderão
        ser armazenados para facilitar acessos e contratações futuras,
        observadas todas as normas decorrentes da Lei Geral de Proteção de Dados
        Pessoais, Lei nº. 13.709, de 14 de agosto de 2018.
      </p>

      <p>
        27. A contratação dos pacotes e planos de serviços poderá ser renovada
        automaticamente pela GREEN NEXT AUTOMACAO LTDA. - ME, independentemente
        de comunicação ao usuário, mediante cobrança periódica da mesma forma de
        pagamento indicada pelo usuário quando da contratação do serviço.
      </p>

      <h3>VI. DO CANCELAMENTO</h3>

      <p>
        28. O usuário poderá cancelar a contratação dos serviços de acordo com
        os termos que forem definidos no momento de sua contratação. Ainda, o
        usuário também poderá cancelar os serviços em até 7 (sete) dias após a
        contratação, mediante contato com o suporte de atendimento do
        ecossistema HYDRA, de acordo com o Código de Defesa do Consumidor (Lei
        no. 8.078/90).
      </p>

      <p>29. O serviço poderá ser cancelado:</p>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;a) pelo usuário: nessas condições os serviços
        somente cessarão quando concluído o ciclo vigente ao tempo do
        cancelamento; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;b) pela GREEN NEXT por violação dos Termos de
        Uso pelo usuário: os serviços serão cessados imediatamente,
        dispensando-se qualquer tipo de notificação ou comunicado prévio. </span
      ><br /><br />

      <h3>VII. DO SUPORTE</h3>

      <p>
        30. Em caso de qualquer dúvida, sugestão ou problema com a utilização de
        qualquer ferramenta disponibilizada pelo ecossistema HYDRA, o usuário
        poderá entrar em contato com o suporte, através do e-mail
        <a href="mailto: greennextbrasil@gmail.com" target="_blank"
          >greennextbrasil@gmail.com</a
        >
        ou através da aba “Fale Conosco” de preenchimento manual pelo usuário no
        site da plataforma HYDRA.
      </p>

      <p>
        31. Todos os serviços de atendimento ao usuário estarão disponíveis de
        segundas-feiras às sextas-feiras, das 09:00 hrs às 18:00 hrs.
      </p>

      <h3>VIII. DAS RESPONSABILIDADES</h3>

      <p>32. É de única e exclusiva responsabilidade do usuário:</p>

      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;a) defeitos ou vícios técnicos causados e
        originados no próprio sistema do usuário; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;b) a correta utilização do ecossistema HYDRA,
        dos serviços ou produtos oferecidos, prezando pela boa convivência, pelo
        respeito e cordialidade entre toda a comunidade de usuários; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;c) pelo cumprimento e respeito ao conjunto de
        regras disposto nesse Termo e Condições Geral de Uso, na respectiva
        Política de Privacidade e na legislação nacional e internacional
        vigentes; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;d) pela proteção aos dados de acesso à sua
        conta, tais como login e senha, bem como por todas as demais informações
        incluídas pelo próprio usuário na plataforma HYDRA. </span
      ><br /><br />

      <p>33. É de responsabilidade da GREEN NEXT:</p>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;a) indicar as características técnicas do
        serviço ou produto escolhido e utilizado pelo usuário; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;b) os defeitos e vícios encontrados no serviço
        ou produto oferecido desde que lhe tenha dado causa; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;c) as informações que foram por ela divulgadas,
        sendo que os comentários ou informações divulgadas por usuários são de
        inteira responsabilidade dos próprios usuários; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;d) os conteúdos ou atividades ilícitas
        praticadas através da sua plataforma. </span
      ><br /><br />

      <p>
        34. A GREEN NEXT não se responsabiliza por links externos contidos em
        seu sistema que possam redirecionar o usuário à ambiente externo a sua
        rede.
      </p>

      <p>
        35. Não poderão ser incluídos links externos ou páginas que sirvam para
        fins comerciais ou publicitários ou quaisquer informações ilícitas,
        violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou
        ofensivas.
      </p>
      <h3>IX. DOS DIREITOS AUTORAIS</h3>

      <p>
        36. O presente Termo e Condições Gerais de Uso concede aos usuários uma
        licença não exclusiva, não transferível e não sublicenciável, para
        acessar e fazer uso da plataforma e dos serviços e produtos por ela
        disponibilizados.
      </p>

      <p>
        37. A estrutura do site ou aplicativo, as marcas, logotipos, nomes
        comerciais, layouts, gráficos e design de interface, imagens,
        ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de
        som e áudio, programas de computador, banco de dados, arquivos de
        transmissão e quaisquer outras informações e direitos de propriedade
        intelectual da razão social GREEN NEXT AUTOMACAO LTDA. - ME, pessoa
        jurídica de direito privado, sociedade empresarial constituída segundo
        as leis da República Federativa do Brasil, inscrita no CPNJ/MF sob o nº.
        40.203.201/0001-00, observados os termos da Lei da Propriedade
        Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98)
        e Lei do Software (Lei nº 9.609/98), estão devidamente reservados.
      </p>

      <p>
        38. Este Termo e Condições de Uso não cede ou transfere ao usuário
        qualquer direito, de modo que o acesso não gera qualquer direito de
        propriedade intelectual ao usuário, exceto pela licença limitada ora
        concedida na modalidade de plano e pacote de serviços por este
        contratado.
      </p>

      <p>
        39. O uso da plataforma pelo usuário é pessoal, individual e
        intransferível, sendo vedado qualquer uso não autorizado, comercial ou
        não-comercial. Tais usos consistirão em violação dos direitos de
        propriedade intelectual da razão social GREEN NEXT AUTOMACAO LTDA. - ME,
        pessoa jurídica de direito privado, sociedade empresarial constituída
        segundo as leis da República Federativa do Brasil, inscrita no CPNJ/MF
        sob o nº. 40.203.201/0001-00, puníveis nos termos da legislação
        aplicável.
      </p>

      <h3>X. DAS SANÇÕES</h3>

      <p>
        40. Sem prejuízo das demais medidas legais cabíveis, a razão social
        GREEN NEXT AUTOMACAO LTDA. - ME, pessoa jurídica de direito privado,
        sociedade empresarial constituída segundo as leis da República
        Federativa do Brasil, inscrita no CPNJ/MF sob o nº. 40.203.201/0001-00
        poderá, a qualquer momento, advertir, suspender ou cancelar a conta do
        usuário:
      </p>
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;a) que violar qualquer dispositivo do presente
        Termo e Condições de Uso; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;b) que descumprir os seus deveres de usuário; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;c) que tiver qualquer comportamento fraudulento,
        doloso ou que ofenda a terceiros; </span
      ><br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;d) que empregar mau uso de suas tecnologias e
        que com isso possa vir a causar danos à terceiros. </span
      ><br /><br />

      <h3>XI. DA RESCISÃO</h3>

      <p>
        41. A não observância das obrigações pactuadas neste Termo e Condições
        de Uso do ecossistema HYDRA ou da legislação aplicável poderá, sem
        prévio aviso, ensejar a imediata rescisão unilateral por parte da razão
        social GREEN NEXT AUTOMACAO LTDA. - ME, pessoa jurídica de direito
        privado, sociedade empresarial constituída segundo as leis da República
        Federativa do Brasil, inscrita no CPNJ/MF sob o nº. 40.203.201/0001-00,
        e o bloqueio de todos os serviços prestados ao usuário.
      </p>

      <h3>XII. DAS ALTERAÇÕES</h3>

      <p>
        42. Os itens descritos no presente instrumento poderão sofrer
        alterações, unilateralmente e a qualquer tempo, por parte de GREEN NEXT
        AUTOMACAO LTDA. - ME, pessoa jurídica de direito privado, sociedade
        empresarial constituída segundo as leis da República Federativa do
        Brasil, inscrita no CPNJ/MF sob o nº. 40.203.201/0001-00, para adequar
        ou modificar os serviços, bem como para atender novas exigências legais.
        As alterações serão veiculadas pelo site
        <a href="https://www.greennext.com.br/" target="_blank"
          >https://www.greennext.com.br</a
        >
        ou pelo aplicativo do ecossistema HYDRA e o usuário poderá optar por
        aceitar o novo conteúdo ou por cancelar o uso dos serviços, caso seja
        assinante de algum serviço.
      </p>

      <p>
        43. Os serviços oferecidos podem, a qualquer tempo e unilateralmente, e
        sem qualquer aviso prévio, ser deixados de fornecer, alterados em suas
        características, bem como restringido para o uso ou acesso.
      </p>

      <h3>XIII. DA POLÍTICA DE PRIVACIDADE</h3>
      <p>
        44. Além do presente Termo de Condições e Uso, o usuário deverá
        consentir com as disposições contidas na respectiva Política de
        Privacidade a ser apresentada a todos os interessados dentro da
        interface da plataforma.
      </p>

      <h3>XIV. DO FORO</h3>
      <p>
        45. Para a solução de controvérsias decorrentes do presente Termo e
        Condições de Uso será aplicada integralmente a legislação brasileira
        vigente.
      </p>
      <p>
        46. Os eventuais litígios deverão ser apresentados no foro da comarca em
        que se encontra a sede da empresa.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    termosController: {
      required: true,
    },
  },
  async mounted() {
    this.termosController.termosContext(this);
  },
};
</script>