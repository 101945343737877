import { Account } from './account';
import jwtDecode from "jwt-decode";
import Vue from 'vue';
export interface JwtAuthToken {
    token: string;
    refresh: string;
    type: string;
}


export function getJwtAuthToken(): JwtAuthToken {
    const auth = localStorage.getItem('auth')    
    const jwtAuthToken: JwtAuthToken = JSON.parse(auth ?? '{}')

    return jwtAuthToken
}

export function getAccount(): Account {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token
}

export function getCompanyID(): number | null {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.companyID
}

export function getAccountGroupID(): number {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.accountGroupID
}


export function getAccountName(): string {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.name
}

export function isAdmin(): boolean {
    const jwtAuthToken: JwtAuthToken = JSON.parse(localStorage.getItem('auth') ?? '')
    const decoded: any = jwtDecode(jwtAuthToken.token)
    const token: Account = decoded.account

    return token.accountGroupID == 1
}

Vue.prototype.getAccountGroupID = getAccountGroupID