import { Post } from '@/module/post/domain/model/post';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface FetchMostSharedPostsRepository {
    (): Promise<Post[]>
}

const fetchMostSharedPostsRepository = (axios: AxiosInstance): FetchMostSharedPostsRepository => async () => {
    try {
        const response: AxiosResponse = await axios.get('/most-shared/post')

        const postsPagination: Post[] = response.data
        return postsPagination
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchMostSharedPostsRepository