export const headers = [
    {
        text: 'Ativo',
        align: 'start',
        sortable: true,
        value: 'active',
        custom: true
    },
    {
        text: 'Nome',
        align: 'start',
        sortable: true,
        value: 'name',
    },
    {
        text: 'Login',
        align: 'start',
        sortable: true,
        value: 'login',
    },
    {
        text: 'Email',
        align: 'start',
        sortable: true,
        value: 'email',
    },
    {
        text: 'Ações',
        value: 'actions',
        align: 'right',
        sortable: false
    }
]
