import { getAccountGroupID } from '@/core/domain/model/jwtAuthToken';
import { FetchUsersUseCase } from '../domain/usecase/fetch_user_usecase';
import { DeleteUserUseCase } from './../domain/usecase/delete_user_usecase';
import { CreateUserUseCase } from './../domain/usecase/create_user_usecase';
import { ChangeUserUseCase } from './../domain/usecase/change_user_usecase';
import { UserPagination } from './../domain/model/user_pagination';
import { DataOptions } from 'vuetify';
import { headers } from './../const/headers';
import { User } from '../domain/model/user';
import { snackbar } from '@/core/controller/snackbar_controller';

class UserController {
  public context: any;
  public dialog: boolean = false;
  public columns: Array<any> = headers
  public search: string = ""
  public flagOption: string = ""
  public loading: boolean = false
  public isAdmin: boolean = false
  public userPagination: UserPagination = {
    total: 0,
    items: []
  }

  public options: DataOptions = {
    page: 1,
    itemsPerPage: 40,
    groupBy: [],
    sortBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortDesc: []
  }

  public user: User = {
    id: 0, active: true, email: "", login: "", name: "", password: "", accountGroupID: 0, companyID: 0, companyName: ""
  }

  public groupItems: Array<Object> = [
    { id: 2, group: "MODERADOR" }
  ]

  private fetchUsersUseCase: FetchUsersUseCase
  private deleteUserUseCase: DeleteUserUseCase
  private createUserUseCase: CreateUserUseCase
  private changeUserUseCase: ChangeUserUseCase

  constructor(
    context: any,
    fetchUsersUseCase: FetchUsersUseCase,
    deleteUserUseCase: DeleteUserUseCase,
    createUserUseCase: CreateUserUseCase,
    changeUserUseCase: ChangeUserUseCase) {
    this.context = context
    this.fetchUsersUseCase = fetchUsersUseCase
    this.deleteUserUseCase = deleteUserUseCase
    this.createUserUseCase = createUserUseCase
    this.changeUserUseCase = changeUserUseCase
  }

  open() {
    if (getAccountGroupID() == 1) {
      this.groupItems.unshift({ id: 1, group: "ADMINISTRADOR" },)
    }
    this.flagOption = "create"
  }

  close() {
    this.context.$refs.crud.resetValidation()
    this.dialog = false
  }

  async paginate() {
    this.loading = true
    try {
      this.userPagination = await this.fetchUsersUseCase(this.options, this.search)
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    } finally {
      this.loading = false
    }
  }

  watchOptions() {
    this.paginate()
  }

  watchSearch() {
    this.paginate()
  }

  async create() {
    if (this.context.$refs.crud.validate()) {
      try {
        switch (this.flagOption) {
          case "create": {
            await this.createUserUseCase(this.user)
            this.paginate()
            snackbar.show({ message: "Criado com sucesso!", color: 'green', timeout: 1000 })
          }
            break
          case "change":
            {
              await this.changeUserUseCase(this.user.id, this.user)
              this.paginate()
              snackbar.show({ message: "Alterado com sucesso!", color: 'blue', timeout: 1000 })
            }
            break
          default:
            break
        }
      } catch (error: any) {
        snackbar.show({ message: error.toString() })
      } finally {
        this.dialog = false
        this.context.$refs.crud.resetValidation()
      }
    }
  }

  async delete(item: User) {
    try {
      const confirm = await snackbar.confirm({ message: "Deseja realmente excluir o registro?", color: 'red' })
      if (confirm) {
        await this.deleteUserUseCase(item.id)
        this.paginate()
        snackbar.show({ message: "Resgistro excluído com sucesso!", color: 'primary', timeout: 1000 })
      }
    } catch (error: any) {
      snackbar.show({ message: error.toString() })
    }
  }

  async change(item: User) {
    this.dialog = true
    this.flagOption = "change"
    this.user = { ...item }
  }
}

export default UserController