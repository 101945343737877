<template>
  <div class="background_screen">
    <v-app-bar
      color="primary"
      clipped-left
      clipped-right
      app
      height="70"
    >
     <v-spacer></v-spacer>
      <v-img
        max-width="200"
        contain
        src="/greennext.png"
      ></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <component :params="controller.params" :is="controller.selected"></component>
    </v-main>
  </div>
</template>

<script>
import { homeController } from "@/di/di";
import Termos from "../components/termos.vue";

export default {
  components: {
    Termos,
  },
  data: (context) => ({
    controller: homeController(context),
  }),
  async mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.dark"() {
      this.$vuetify.theme.dark = this.controller.dark;
      localStorage.setItem("dark", this.controller.dark);
    },
  },
};
</script>

<style scoped>
.card:hover {
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
}
.notification-card {
  top: 70px;
  float: right;
  position: fixed;
  /* margin-top: 70px; */
  right: 100px;
  width: 430px;
  z-index: 9999;
}
.avatar-card {
  top: 70px;
  float: right;
  position: fixed;
  /* margin-top: 70px; */
  right: 10px;
  width: 250px;
  z-index: 9999;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 1px;
  z-index: 1;
  top: 1px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 192px;
  height: 192px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style> 
