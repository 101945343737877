import { PostPagination } from '../../domain/model/post_pagination';
import { Pagination } from '@/core/domain/model/pagination';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export interface FetchDraftPostsRepository {
    (pagination: Pagination): Promise<PostPagination>
}

const fetchDraftPostsRepository = (axios: AxiosInstance): FetchDraftPostsRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/draft/post', {
            params: pagination
        })

        const postsPagination: PostPagination = response.data
        return postsPagination
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchDraftPostsRepository