import { getCompanyID } from '@/core/domain/model/jwtAuthToken';
import LoginPage from '../../login/view/login_page.vue'
import HomePage from '../../home/view/home_page.vue'

class DefaultController {
    context: any;
    selected: any = LoginPage;

    constructor(context: any) {
        this.context = context;
    }

    mounted() {
        window.router = this;
        if (localStorage.getItem('auth') !== null && getCompanyID() !== null) {
            this.selected = HomePage
            return
        }

        this.selected = LoginPage
    }

    login() {
        //localStorage.clear()
        localStorage.removeItem('auth')
        this.selected = LoginPage
    }

    home() {
        this.selected = HomePage
    }
}

export default DefaultController