<template>
  <v-row>
    <input
      style="display: none"
      type="file"
      id="input-file"
      @change="controller.change($event)"
    >
    <v-col cols="12">
      <v-toolbar elevation="0">
        <v-icon @click="navigation.navigate(1)">mdi-arrow-left</v-icon>
        <v-switch
          class="mt-5 ml-5"
          v-model="controller.draft"
          inset
          label="Rascunho"
        ></v-switch>
        <v-switch
          class="mt-5 ml-5"
          v-model="controller.highlighted"
          inset
          label="Em destaque"
        ></v-switch>
        <v-spacer></v-spacer>

        <!-- <v-btn
          class="mx-2"
          color="blue"
          dark
          @click="controller.preview = true"
        >
          <v-icon left>mdi-eye</v-icon>preview
        </v-btn> -->
        <v-btn
          color="primary"
          @click="controller.save()"
        >
          <v-icon left>mdi-plus</v-icon> salvar
        </v-btn>
      </v-toolbar>
    </v-col>
    <!-- <v-col lg="4">
      <div id="editorjs" />
    </v-col> -->
    <v-col
      lg="4"
      class="px-10"
    >
      <v-textarea
        id="title"
        class="elevation-0"
        type="text"
        auto-grow
        v-model="controller.title"
        placeholder="Título do post..."
        style="font-size: 45px;  outline-width: 0; font-weight: bold; width: 100%;"
        solo
        rows="1"
        flat
      >
      </v-textarea>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              Configurações do post
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="d-flex flex-column">
              <v-col>
                <v-select
                  dense
                  filled
                  label="Categoria"
                  v-model="controller.category"
                  :items="controller.categoryPagination.items"
                  item-text="name"
                  item-value="id"
                ></v-select>
                <v-select
                  dense
                  filled
                  label="Tags"
                  v-model="controller.tags"
                  :items="controller.tagPagination.items"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  small-chips
                ></v-select>
                <v-select
                  dense
                  filled
                  label="Posts relacionados"
                  v-model="controller.relatedPosts"
                  :items="controller.postPagination.items"
                  item-text="title"
                  item-value="id"
                  multiple
                  chips
                  small-chips
                ></v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              Configurações de SEO
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="d-flex flex-column">
              <v-col>
                <v-card style="border-radius: 20px">
                  <v-img
                    :aspect-ratio="16/9"
                    style="border-radius: 20px"
                    class="d-flex align-center justify-center"
                    :src="controller.image"
                  ></v-img>
                  <v-responsive
                    :aspect-ratio="16/9"
                    style="position: absolute; top:0; left: 0; right:0; border-radius: 20px"
                    @click="controller.uploadImage()"
                  >
                    <v-card
                      ripple
                      style="background: rgba(0, 206, 1, 0.2); cursor: pointer; border-radius: 20px"
                      height="100%"
                      class="d-flex justify-center align-center flex-column"
                    >
                      <div class="py-5 font-weight-bold white--text">Selecionar imagem</div>
                      <v-btn
                        fab
                        color="white"
                        outlined
                        readonly
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>

                    </v-card>
                  </v-responsive>
                </v-card>
              </v-col>
              <v-col>
                <v-text-field
                  solo
                  placeholder="Slug"
                  :readonly="controller.isChange"
                  v-model="controller.slug"
                ></v-text-field>
                <v-textarea
                  solo
                  placeholder="Descrição SEO"
                  v-model="controller.description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-divider
      vertical
      style="height: 83vh"
    ></v-divider>
    <v-col
      lg="8"
      class="mt-10"
    >
      <div class="editor-wrapper">
        <vue-editor
          style="overflow: auto; max-height: 79vh;"
          v-model="controller.content"
          :editor-options="editorSettings"
        ></vue-editor>
      </div>
    </v-col>

    <v-dialog
      scrollable
      max-width="800"
      v-model="controller.preview"
    >
      <v-card>
        <v-img
          :aspect-ratio="16/9"
          class="d-flex align-center justify-center"
          :src="controller.image"
        ></v-img>
        <v-card-text>
          <div
            class="my-5 px-3"
            style="font-size: 70px; font-weight: bold"
          >
            {{controller.title}}
          </div>
          <!-- <div>{{controller.content}}</div> -->
          <vue-editor
            v-model="controller.content"
            :editor-options="{readOnly: true, theme: 'bubble'}"
          ></vue-editor>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { postEditorController } from "@/di/di";

import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
// import * as QuillTableUI from 'quill-table-ui'

// Quill.register({
//   'modules/tableUI': QuillTableUI
// }, true)

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

export default {
  components: {
    VueEditor,
  },
  name: "Editor",
  data: (context) => ({
    controller: postEditorController(context),
    editorSettings: {
      readOnly: false,
      theme: "snow",

      modules: {
        imageDrop: true,
        imageResize: {
          modules: ["Resize", "DisplaySize", "Toolbar"],
        },
        // table: true,
        // tableUI: true,
      },
    },
  }),
  async mounted() {
    this.controller.mounted();
  },
  watch: {
    "controller.title"() {
      this.controller.watchTitle();
    },
  },
};
</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

.cdx-settings-button[data-tune="withBorder"],
.cdx-settings-button[data-tune="withBackground"],
.cdx-settings-button[data-tune="stretched"] {
  display: none;
}

.editor-wrapper {
  position: relative;
}

.ql-toolbar {
  position: absolute;
  top: -95px;
  width: 100%;
  transform: translateY(100%);
  z-index: 9999;
}

#title {
  line-height: 45px !important;
}

#quill-container {
  border: none;
}
</style>

<style lang="sass" scoped>
$color: #00CC8F
$background: #fff

// Blockquote CSS
blockquote
  border-left: 3px solid $color
  color: #1a1a1a
  font-family: Georgia, Times, "Times New Roman", serif
  font-size: 1.25em
  font-style: italic
  line-height: 1.8em
  margin: 1.1em -4em
  padding: 1em 2em
  position: relative
  transition: .2s border ease-in-out
  z-index: 0
  &:before
    content: ""
    position: absolute
    top: 50%
    left: -4px
    height: 2em
    background-color: $background
    width: 5px
    margin-top: -1em
  &:after
    content: "\f35f"
    position: absolute
    top: 50%
    left: -0.5em
    color: $color
    font-family: "Ionicons"
    font-style: normal
    line-height: 1em
    text-align: center
    text-indent: -2px
    width: 1em
    margin-top: -0.5em
    transition: .2s all ease-in-out, .4s transform ease-in-out
  &.format:before, &.format:after
    display: none
  &.book:after
    content: "\f12e"
  &.podcast:after
    content: "\f32d"
  &.lyrics:after
    content: "\f333"
  &.favorite:after
    content: "\f384"
  &:active:after
    transform: rotateY(360deg)

  cite
    display: block
    font-size: 0.75em
    line-height: 1.8em
    margin-top: 1em
</style>


