import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Tag } from '../../domain/model/tag';

export interface ChangeTagRepository {
  (id: number, data: object): Promise<Tag>
}

const changeTagRepository = (axios: AxiosInstance): ChangeTagRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/tag/${id}`, data)
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default changeTagRepository

