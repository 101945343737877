import { ChangeTagRepository } from '../../data/repository/change_tag_repository';
import { Tag } from '../model/tag';

export interface ChangeTagUseCase {
    (id: number, data: object): Promise<Tag>
}

const changeTagUseCase = (repository: ChangeTagRepository): ChangeTagUseCase => async (id:number, data: Object) => {
    try {
        return await repository(id, data);
    } catch (error) {
        throw error
    }
}

export default changeTagUseCase;

