import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import Handler from '@/core/domain/usecase/handler';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { User } from '../../domain/model/user';

export interface ChangeUserRepository {
  (id: number, data: object): Promise<User>
}

const changeUserRepository = (axios: AxiosInstance): ChangeUserRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/account/${id}`, data)
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default changeUserRepository

