import animationData from '../../../assets/dashboard_charts.json'

class DashboardController {
  private context: any
  public lottieAnimation: object = { animationData: animationData }

  constructor(
    context: any,
  ) {
    this.context = context
  }

  async mounted() {

  }

}

export default DashboardController