import { Category } from './../../domain/model/category';
import { CategoryPagination } from '../../domain/model/category_pagination';
import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { Pagination } from '@/core/domain/model/pagination';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface DeleteCategoryRepository {
    (id: number): Promise<Category>
}

const deleteCategoryRepository = (axios: AxiosInstance): DeleteCategoryRepository => async (id: number) => {
    try {
        const response: AxiosResponse = await axios.delete(`/cateogory/${id}`)
        const category: Category = response.data
        return category
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default deleteCategoryRepository