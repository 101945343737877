import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Post } from '../../domain/model/post';

export interface ChangePostRepository {
  (id: number, data: object): Promise<Post>
}

const changePostRepository = (axios: AxiosInstance): ChangePostRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/post/${id}`, data)
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default changePostRepository

