import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import { Category } from '../../domain/model/category';

export interface ChangeCategoryRepository {
  (id: number, data: object): Promise<Category>
}

const changeCategoryRepository = (axios: AxiosInstance): ChangeCategoryRepository => async (id: number, data: object) => {
  try {
    const response: AxiosResponse = await axios.put(`/category/${id}`, data)
    return response.data
  } catch (err: any | AxiosError) {
    throw err
  }
}

export default changeCategoryRepository

