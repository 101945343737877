import { TagPagination } from '../../domain/model/tag_pagination';
import { JwtAuthToken } from '@/core/domain/model/jwtAuthToken';
import { Pagination } from '@/core/domain/model/pagination';
import { RefreshUseCase } from '@/core/domain/usecase/refresh_usecase';
import { AxiosResponse, AxiosInstance, AxiosError } from 'axios';
import Handler from '@/core/domain/usecase/handler';

export interface FetchTagRepository {
    (pagination: Pagination): Promise<TagPagination>
}

const fetchTagRepository = (axios: AxiosInstance): FetchTagRepository => async (pagination: Pagination) => {
    try {
        const response: AxiosResponse = await axios.get('/tag', {
            params: pagination
        })

        const tagPagination: TagPagination = response.data
        return tagPagination
    } catch (err: any | AxiosError) {
        throw err
    }
}

export default fetchTagRepository